import React, { useState, useEffect } from "react";
//import styles from "./Main.module.css";
import Login from "../login/Login";
import Validation from "../validation/Validation";
import NavigationPage from "./NavigationPage";
import SignUp from "../user/SignUp";

const Main = ({ props }) => {
    /* Change to setActive similar to navigation */
    const [showValidation, setShowValidation] = useState(false);
    const [showLogin, setShowLogin] = useState(true);
    const [showSignup, setShowSignup] = useState(false);
    const [showNavigationPage, setShowNavigationPage] = useState(false);
    const [emailAddress, setEmailAddress] = useState(null);
    const [serialNumber, setSerialNumber] = useState(null);
    const [placeOfPurchase, setPlaceOfPurchase] = useState(null);
    const [categoryChoice, setCategoryChoice] = useState({ CategoryID: 0, Category: '' });
    const [product, setProduct] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => { }, [user]);

    const clickShowValidation = (showValidation) => {
        console.log(`Show Validation Form: ${showValidation}`);
        setShowValidation(showValidation);
        setShowLogin(!showValidation);
        setShowSignup(false);
    };

    const updateShowSignup = (doShowSignUp) => {
        setShowSignup(doShowSignUp);
        setShowLogin(!doShowSignUp);
        setShowValidation(!doShowSignUp);
    }

    const updateShowLogin = (doShowLogin) => {
        setShowSignup(false);
        setShowLogin(doShowLogin);
        setShowValidation(!doShowLogin);
        setShowNavigationPage(false);
    }

    const Logout = () => {
        updateShowLogin(true);
        setUser(null);
    }

    const loginUser = (user) => {
        setUser(user);
        setShowLogin(false);
        setShowNavigationPage(true);
    }

    return (
        <React.Fragment>           
            {!showValidation && showLogin ?
                <Login props={props} clickShowValidation={clickShowValidation} setUser={loginUser} emailAddress={emailAddress} /> : null}
            {showValidation && !showLogin ?
                <Validation clickShowValidation={clickShowValidation} updateShowSignup={updateShowSignup} setEmailAddress={setEmailAddress}
                emailAddress={emailAddress} placeOfPurchase={placeOfPurchase}
                setPlaceOfPurchase={setPlaceOfPurchase} serialNumber={serialNumber} setSerialNumber={setSerialNumber}
                categoryChoice={categoryChoice} setCategoryChoice={setCategoryChoice} setProduct={setProduct} props={props}/> : null}
            {showSignup ?
                <SignUp props={props} clickShowValidation={clickShowValidation}
                emailAddress={emailAddress} product={product} updateShowLogin={updateShowLogin} /> : null}
            {showNavigationPage ?
                <NavigationPage props={props} user={user} updateShowLogin={Logout} product={product} /> : null}
        </React.Fragment>
    )
};

export default Main;
