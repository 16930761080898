import React, { useState, useEffect } from "react";
//import { camelize } from "../../service";
import Address from "./Address";
import styles from "./SignUp.module.css";
import SignAgreement from '../validation/SignAgreement';
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    //MDBCardImage,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBAccordion,
    MDBAccordionItem
} from 'mdb-react-ui-kit';

import { faEnvelope, faFileSignature, faAddressCard, faUserShield, faCog, faShieldAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const regexConfirmPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&?]).{8,}$/;
const NO_MATCH = "Passwords don't Match!";
const ENTER_PASSWORD = 'Please Enter a Password';
const EIGHT_CHARACTERS = 'Password must be at least 8 Characters';
const REQ_DIGITS = 'At least one lowercase letter, one uppercase letter, one number, and one special character';
const VALID = 'Password Valid ✔';
const MATCH = 'Passwords Match ✔';
const SHOW_PASSWORD = 'Show Password';
const HIDE_PASSWORD = 'Hide Password';
const ADDRESS_HEADER = 'Shipping Address and Details';
const AGREEMENT_HEADER = 'Read and Sign Agreement';
const SECURITY_HEADER = 'Security and Registration';
const EMAIL_AND_ORDER_HEADER = 'E-mail and Order Details';
const VALIDATION_SERIAL = 'Serial';
const VALIDATION_ORDER = 'Order';
const SignUp = ({ props, clickShowValidation, emailAddress, product, updateShowLogin }) => {
    const [showPassword, setShowPassword] = useState('password');
    const [showPasswordText, setShowPasswordText] = useState(SHOW_PASSWORD);
    const [errorPassword, setErrorPassword] = useState(ENTER_PASSWORD);
    const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [isAddressValid, setIsAddressValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isPasswordMatch, setIsPasswordMatch] = useState(false);
    const [isAgreementSigned, setIsAgreementSigned] = useState(false);
    useEffect(() => { checkValid(); }, [isPasswordMatch, isPasswordValid, isAddressValid, isAgreementSigned]);
    const [isValid, setIsValid] = useState(false);
    const [address, setAddress] = useState(null);
    const [sigUrl, setSigUrl] = useState(null);
    const [agreementDoc, setAgreementDoc] = useState(null);
    const [active, setActive] = useState(1);
    const [doSpin, setDoSpin] = useState(false);
    let btnClass = `mb-4 px-5 ${(props.doOutlineButton ? `btn-outline-${props.buttonColour}`  : '')}`;

    const register = () => {
        if (!isValid) return;
        let customer = {
            customer: {
                Product: product,
                Address: address,
                Password: password,
                UserName: emailAddress,
                Site: props.brand,
                Signature: sigUrl,
                Document: agreementDoc,
                SalesEmail: props.mainSalesEmail,
                MainEmail: props.mainEmail,
                RegisteredURL: window.location.href,
            }
        }

        const requestOptions = {
            method: "post",
            headers: props.header,
            redirect: "follow",
            changeOrigin: true,
            body: JSON.stringify(customer),
        };

        fetch(`${props.baseUrl}User`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(`[SignUp][register]> Result: ${result}`);
                /* Validate Entries: Confirm Serial number is valid for account, get place of purchase */
                result = JSON.parse(result);
                if (result && result.status && result.status === 200) {
                    console.log(`[SignUp][register][SUCCESS]> ${result}`);
                    alert(result.message);
                    updateShowLogin(true);
                } else {
                    console.log(`[SignUp][register][FAILED]> ${result}`);
                    if (result && result.message) {
                        alert(result.message);
                    } else {
                        alert('There appears to have been an issue with registration...');
                    }
                }
            })
            .catch((error) => console.error('[SignUp][register][ERROR]> There was an issue logging in: ' + error));
    }

    const isValidPassword = (password) => {
        return regexConfirmPassword.test(password);
    }

    const updateAddress = (address) => {
        if (address) {
            setAddress(address);
            setIsAddressValid(true);
        } else {
            setAddress(null);
            setIsAddressValid(false);
        }
        checkValid();
    }

    const checkValid = () => {
        console.log(`isAddressValid: ${isAddressValid} && isPasswordValid: ${isPasswordValid} && isPasswordMatch: ${isPasswordMatch}`)
        if (isAddressValid && ((isPasswordValid && isPasswordMatch) || (errorPassword === VALID && errorConfirmPassword === MATCH)) && ((props.isAdpCustomer && isAgreementSigned) || !props.isAdpCustomer)) { setIsValid(true); return true; }
        else { setIsValid(false); return false; }
    }

    function isMatch(pass, confPass) {
        if (confPass !== pass) {
            console.log('isMatch', NO_MATCH);
            setErrorConfirmPassword(NO_MATCH);
            setIsPasswordMatch(false);
        }
        else {
            console.log('isMatch', MATCH);
            setErrorConfirmPassword(MATCH);
            setIsPasswordMatch(true);
        }
    }

    function verifyPassword(e) {
        setPassword(e.target.value);
        if (!e.target.value || e.target.value.length === 0) { setErrorPassword(ENTER_PASSWORD); }
        else if (e.target.value.length > 0 && e.target.value.length < 8) setErrorPassword(EIGHT_CHARACTERS);
        else if (isValidPassword(e.target.value)) {
            setIsPasswordValid(true);
            setErrorPassword(VALID);
        } else {
            setErrorPassword(REQ_DIGITS);
            setIsPasswordValid(false);
        }
        isMatch(e.target.value, confirmPassword);
        checkValid();
    }

    function verifyConfirmPassword(e) {
        setConfirmPassword(e.target.value);
        isMatch(password, e.target.value);
        checkValid();
    }

    const updateShowPassword = () => {
        if (showPassword === 'password') {
            setShowPassword('text');
            setShowPasswordText(HIDE_PASSWORD);
        }
        else
            if (showPassword === 'text') {
                setShowPassword('password');
                setShowPasswordText(SHOW_PASSWORD);
            }
    }

    const updateAgreementSigned = (isSigned) => {
        setIsAgreementSigned(isSigned);
    }

    console.log(props.image);
    return (
        <React.Fragment>
            <MDBContainer style={styles} className="my-5">  <MDBCard className='p-3'>
                <div className='d-flex flex-row m-2 pb-4' onClick={() => setDoSpin(!doSpin)}>
                    {doSpin ? <FontAwesomeIcon size="4x" icon={faCog} spin />
                        : <FontAwesomeIcon style={{ color: props.textIconColour, }} size="4x" icon={faCog} />}
                    <span style={{ color: props.textIconColour, }} className="h1 fw-bold mb-0 mx-3 mt-2">{props.companyName} Registation</span>
                </div>
                <MDBAccordion initialActive={'itmEmailOrders'} active={active} onChange={(itemId) => setActive(itemId)}>

                    <MDBAccordionItem key={'itmEmailOrders'} collapseId={'itmEmailOrders'} headerTitle={
                        <div className='d-flex flex-row mt-2 '>
                            {active === 'itmEmailOrders' ?
                                <FontAwesomeIcon  size="4x" icon={faEnvelope} transform={{ rotate: 15 }} />
                                : <FontAwesomeIcon style={{ color: props.textIconColour, }} size="3x" icon={faEnvelope} />
                            }
                            <span style={{ color: props.textIconColour, }} className="h2 fw-bold mb-0 mx-3">{EMAIL_AND_ORDER_HEADER}
                                <FontAwesomeIcon style={{ color: props.passIconColour, }} size="1x" icon={faCheckCircle} className="mx-3" /></span>
                        </div>}>
                        <MDBCardBody className='d-flex flex-column'>
                            <MDBInput wrapperClass='mb-4' label='Email address' id='formControlEmail' type='email' size="lg" value={emailAddress} disabled />
                            <MDBInput wrapperClass='mb-4' label={`${(props.isAdpCustomer ? VALIDATION_ORDER : VALIDATION_SERIAL)} Number`} id='formControlSerial' type='text' size="lg" value={(props.isAdpCustomer ? product.OrderNumber : product.SerialNumber)} disabled />
                            <MDBInput wrapperClass='mb-4' label='Place of Purchase' id='formControlPOP' type='text' size="lg" value={product.PlaceOfPurchase} disabled />
                        </MDBCardBody>
                    </MDBAccordionItem>
                    {props.isAdpCustomer ?
                        <MDBAccordionItem key={'itmSignAgreement'} collapseId={'itmSignAgreement'} headerTitle={
                            <div className='d-flex flex-row mt-2 '> {active === 'itmSignAgreement' ?
                                <FontAwesomeIcon size="4x" icon={faFileSignature} transform={{ rotate: 15 }} />
                                : <FontAwesomeIcon style={{ color: props.textIconColour, }} size="3x" icon={faFileSignature} />
                            }
                                <span style={{ color: props.textIconColour, }} className="h2 fw-bold mb-0 mx-3">{`${AGREEMENT_HEADER}`}{isAgreementSigned ?
                                    <FontAwesomeIcon style={{ color: props.passIconColour, }} size="1x" icon={faCheckCircle} className="mx-3" /> : ''}{sigUrl ? <img src={sigUrl} height='30' alt='Signature' loading='lazy' /> : null}</span>
                            </div>}>
                            <SignAgreement props={props} updateSetActive={() => setActive('itmAddress')} updateIsAgreementSigned={updateAgreementSigned} setSigUrl={setSigUrl} setAgreementDoc={setAgreementDoc} />
                    </MDBAccordionItem>
                        : null}
                    <MDBAccordionItem key={'itmAddress'} collapseId={'itmAddress'} headerTitle={
                        <div className='d-flex flex-row mt-2 '>{active === 'itmAddress' ?
                            <FontAwesomeIcon size="4x" icon={faAddressCard} transform={{ rotate: 15 }} />
                            : <FontAwesomeIcon style={{ color: props.textIconColour, }} size="3x" icon={faAddressCard} />
                        }
                            <span style={{ color: props.textIconColour, }} className="h2 fw-bold mb-0 mx-3">{`${ADDRESS_HEADER}`}{isAddressValid ?
                                <FontAwesomeIcon style={{ color: props.passIconColour, }} size="1x" icon={faCheckCircle} className="mx-3" /> :''}
                                </span>
                        </div>}>
                        <Address props={props} setAddress={updateAddress} />
                    </MDBAccordionItem>

                    <MDBAccordionItem key={'itmSecuity'} collapseId={'itmSecuity'} headerTitle={
                        <div className='d-flex flex-row mt-2 '>{active === 'itmSecuity' ?
                            <FontAwesomeIcon size="4x" icon={faShieldAlt} transform={{ rotate: 15 }} />
                            : <FontAwesomeIcon style={{ color: props.textIconColour, }} size="3x" icon={faUserShield} />
                        }
                            <span style={{ color: props.textIconColour, }} className="h2 fw-bold mb-0 mx-3">{SECURITY_HEADER} {isPasswordValid && isPasswordMatch ?
                                <FontAwesomeIcon style={{ color: props.passIconColour, }} size="1x" icon={faCheckCircle} className="mx-3" /> :''}</span>
                        </div>}>
                        <MDBCardBody className='d-flex flex-column'>
                            <MDBCol md='6'>
                                <a href="#!" onClick={updateShowPassword} className="small text-muted text-end">{showPasswordText}</a>
                                <MDBInput
                                    wrapperClass='mb-4'
                                    aria-describedby='passwordErrorLabel'
                                    label='Password'
                                    id='formPassword'
                                    onChange={verifyPassword}
                                    type={showPassword} size="lg" style={errorPassword !== VALID ? props.errorStyle : null} >
                                    <div id='passwordErrorLabel' className='form-helper'>
                                        {errorPassword}
                                    </div>
                                </MDBInput>

                                <MDBInput
                                    wrapperClass='mb-4'
                                    aria-describedby='passwordConfirmErrorLabel'
                                    label='Confirm Password'
                                    id='formConfirmPassword'
                                    onChange={verifyConfirmPassword}
                                    type={showPassword} size="lg" style={errorConfirmPassword !== MATCH ? props.errorStyle : null}  >
                                    <div id='passwordConfirmErrorLabel' className='form-helper'>
                                        {errorConfirmPassword}
                                    </div>
                                </MDBInput>
                                <MDBBtn className={btnClass} color={props.buttonColour} size='lg' disabled={!isValid} onClick={register}>Register</MDBBtn>

                            </MDBCol>
                        </MDBCardBody>

                    </MDBAccordionItem>

                    <MDBRow className='g-0 p-2'>
                        <MDBCol md='12'>
                            <div className='d-flex flex-row justify-content-center'>
                                <a className="small text-muted" href="#!" onClick={() => updateShowLogin(true)}>Back to Login</a>

                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='g-0 p-2'>
                        <MDBCol md='12'>
                            <div className='d-flex flex-row justify-content-center'>
                                <a href="#!" className="small text-muted me-1">Terms of use.</a>
                                <a href="#!" className="small text-muted">Privacy policy</a>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBAccordion>
            </MDBCard>
            </MDBContainer>
        </React.Fragment>
    );
}
export default SignUp;