import React from 'react';

export default function ViewPDF({ props, pdf }) {
	console.log('View PDF:', pdf);

	return (
		<React.Fragment>
			<div style={{ maxHeight: 800, height:800, border: '5px inset', marginBottom: 10 }}>
				<object data={pdf} type="application/pdf" width="100%" height="100%">
					<p>Link <a href={pdf}>to the PDF!</a></p>
				</object>				
			</div>
		</React.Fragment>);
}




























