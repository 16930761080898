import React, { useState, useEffect } from "react";
//import { camelize } from "../../service";
import Address from "./Address";
import styles from "./Manage.module.css";
import ViewPDF from '../utilities/ViewPDF';
import ManageUserDetails from './ManageUserDetails';
import { faEnvelope, faAddressCard, faUserShield, faFileSignature, faUser } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as CnbLogo } from '../../CnbLogo.svg';
import { ReactComponent as RefurbLogo } from '../../Refurbio.svg';
import { ReactComponent as SysLiqLogo } from '../../SysLiqLogo.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    //MDBCardImage,
    MDBRow,
    MDBCol,
    //MDBIcon,
    MDBInput
} from 'mdb-react-ui-kit';
const regexConfirmPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&?]).{8,}$/;
const NO_MATCH = "Passwords don't Match!";
const ENTER_PASSWORD = 'Please Enter a New Password';
const ENTER_CURRENT_PASSWORD = 'Please Enter your Current Password';
const EIGHT_CHARACTERS = 'Password must be at least 8 Characters';
const REQ_DIGITS = 'At least one lowercase letter, one uppercase letter, one number, and one special character';
const VALID = 'Password Valid ✔';
const MATCH = 'Passwords Match ✔';
const SHOW_PASSWORD = 'Show Password';
const HIDE_PASSWORD = 'Hide Password';
const ADDRESS_HEADER = 'Shipping Address';
const SECURITY_HEADER = 'Security - Change Password';
const USER_HEADER = 'Update User Details';
const Manage = ({ props, user, rmaComp, setNavName }) => {
    const [showPassword, setShowPassword] = useState('password');
    const [showPasswordText, setShowPasswordText] = useState(SHOW_PASSWORD);
    const [errorPassword, setErrorPassword] = useState(ENTER_PASSWORD);
    const [errorPasswordCurrent, setErrorPasswordCurrent] = useState(ENTER_PASSWORD);
    const [errorEmail, setErrorEmail] = useState(null);
    const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [isAddressValid, setIsAddressValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isCurrentPasswordValid, setIsCurrentPasswordValid] = useState(false);
    const [isPasswordMatch, setIsPasswordMatch] = useState(false);
    useEffect(() => { checkValid(); }, [isPasswordMatch, isPasswordValid, isCurrentPasswordValid,]);
    const [isValid, setIsValid] = useState(false);
    const [address, setAddress] = useState(null);
    const [isUserValid, setIsUserValid] = useState(false);
    const [emailAddress, setEmailAddress] = useState(rmaComp.Email);
    const [showAgreement, setShowAgreement] = useState(false);
    const [agreement, setAgreement] = useState(props.agreementDoc);
    const [userDetails, setUserDetails] = useState(null);
    const clickShow = () => setShowAgreement(!showAgreement);
    useEffect(() => { }, [emailAddress, errorEmail]);

    let btnClass = `mb-4 px-5 ${(props.doOutlineButton ? `btn-outline-${props.buttonColour}` : '')}`;

    const getUserDetails = () => {
        const requestOptions = {
            method: "post",
            headers: props.header,
            redirect: "follow",
            changeOrigin: true,
            body: JSON.stringify(
                {
                    UserName: user.UserName,
                    Token: user.Token,
                    User: user,
                }
            ),
        };

        fetch(`${props.baseUrl}User/details`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(`[Manage][getUserDetails]> Result: ${result}`);
                /* Validate Entries: Confirm Serial number is valid for account, get place of purchase */
                result = JSON.parse(result);
                if (result && result.status && result.status === 200) {
                    console.log(`[Manage][getUserDetails][SUCCESS]> ${result}`);
                    setUserDetails(result.user);
                    //if (result.agreement && result.agreement.Agreement) {
                    //    var blobObj = new Blob(result.agreement.Agreement.data, { type: "application/pdf" });
                    //    let url = window.URL.createObjectURL(blobObj);

                    //    console.log(`[Manage][getUserDetails][SUCCESS]> url: ${result}`);
                    //    setAgreement(url);
                    //}
                } else {
                    console.log(`[Manage][getUserDetails][FAILED]> ${result}`);
                    if (result && result.message) {
                        alert(result.message);
                    } else {
                        alert('There appears to have been an issue with getting the details...');
                    }
                }
            })
            .catch((error) => console.error('[Manage][getUserDetails][ERROR]> There was an Updating company details: ' + error));
    }


    /**
     * Used for onChange in text box which feeds argument, checks validity and sets state
     * @param {any} e
     */
    function updateEmail(e) {
        setEmailAddress(e.target.value);
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        console.log(`updateEmail: ${e.target.value}`, 'Pass RegEx:', (emailRegex.test(e.target.value)), 'Pass Length: ', !(e.target.value.length < 5));
        if (!emailRegex.test(e.target.value)) {
            setErrorEmail('Please enter a valid email address');
        } else if (e.target.value === rmaComp.Email) {
            setEmailAddress(e.target.value);
            setErrorEmail('This is the current contact email address');
        } else {
            setEmailAddress(e.target.value);
            setErrorEmail(null);
        }
    }

    const saveEmail = () => {
        if (errorEmail) return;
        const requestOptions = {
            method: "post",
            headers: props.header,
            redirect: "follow",
            changeOrigin: true,
            body: JSON.stringify(
                {
                    UserName: user.UserName,
                    Token: user.Token,
                    User: user,
                    Site: props.brand,
                    Email: emailAddress,
                    RegisteredURL: window.location.href,
                }
            ),
        };

        fetch(`${props.baseUrl}User/Company/Update/ContactEmail`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(`[Manage][saveEmail]> Result: ${result}`);
                result = JSON.parse(result);
                if (result && result.status && result.status === 200) {
                    console.log(`[Manage][saveEmail][SUCCESS]> ${result}`);
                    alert(result.message);
                } else {
                    console.log(`[Manage][saveEmail][FAILED]> ${result}`);
                    if (result && result.message && result.status && result.status === 400) {
                        setErrorEmail(result.message);
                    } else if (result && result.message && result.status && result.status === 500) {
                        alert(result.message);
                        setErrorEmail(result.message);
                    } else {
                        alert('There appears to have been an issue with updating the password...');
                    }
                }
            })
            .catch((error) => console.error('[Manage][updatePassword][ERROR]> There was an issue logging in: ' + error));

    }

    const updatePassword = () => {
        if (!isValid) return;
        const requestOptions = {
            method: "post",
            headers: props.header,
            redirect: "follow",
            changeOrigin: true,
            body: JSON.stringify(
                {
                    UserName: user.UserName,
                    Token: user.Token,
                    User: user,
                    Site: props.brand,
                    CurrentPassword: currentPassword,
                    Password: password,
                    RegisteredURL: window.location.href,
                }
            ),
        };

        fetch(`${props.baseUrl}User/Password/Update`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(`[Manage][updatePassword]> Result: ${result}`);
                result = JSON.parse(result);
                if (result && result.status && result.status === 200) {
                    console.log(`[Manage][updatePassword][SUCCESS]> ${result}`);
                    alert(result.message);
                } else {
                    console.log(`[Manage][updatePassword][FAILED]> ${result}`);
                    if (result && result.message && result.status && result.status === 400) {
                        setErrorPasswordCurrent(result.message);
                    } else if (result && result.message && result.status && result.status === 500) {
                        alert(result.message);
                        setErrorPassword(result.message);
                    } else {
                        alert('There appears to have been an issue with updating the password...');
                    }
                }
            })
            .catch((error) => console.error('[Manage][updatePassword][ERROR]> There was an issue logging in: ' + error));

    }

    const update = () => {
        if (!isAddressValid) return;
        rmaComp.Email = emailAddress;
        user.Language = address.PreferredLanguage && address.PreferredLanguage.ID ? address.PreferredLanguage.ID : user.Language;
        const requestOptions = {
            method: "post",
            headers: props.header,
            redirect: "follow",
            changeOrigin: true,
            body: JSON.stringify(
                {
                    UserName: user.UserName,
                    Token: user.Token,
                    User: user,
                    RmaComp: rmaComp,
                    Address: address,
                    Site: props.brand,
                    RegisteredURL: window.location.href,
                }
            ),
        };

        fetch(`${props.baseUrl}User/Company/Update`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(`[Manage][update]> Result: ${result}`);
                /* Validate Entries: Confirm Serial number is valid for account, get place of purchase */
                result = JSON.parse(result);
                if (result && result.status && result.status === 200) {
                    console.log(`[Manage][update][SUCCESS]> ${result}`);
                    alert(result.message);
                } else {
                    console.log(`[Manage][update][FAILED]> ${result}`);
                    if (result && result.message) {
                        alert(result.message);
                    } else {
                        alert('There appears to have been an issue with updating the details...');
                    }
                }
            })
            .catch((error) => console.error('[Manage][update][ERROR]> There was an Updating company details: ' + error));
    }

    const isValidPassword = (password) => {
        return regexConfirmPassword.test(password);
    }

    const updateAddress = (address) => {
        if (address) {
            setAddress(address);
            setIsAddressValid(true);
        } else {
            setAddress(null);
            setIsAddressValid(false);
        }
        checkValid();
    }

    const checkValid = () => {
        console.log(`isPasswordValid: ${isPasswordValid} && isPasswordMatch: ${isPasswordMatch}`)
        if ((isPasswordValid && isPasswordMatch) || (errorPassword === VALID && errorConfirmPassword === MATCH)) { setIsValid(true); return true; }
        else { setIsValid(false); return false; }
    }

    function isMatch(pass, confPass) {
        if (confPass !== pass) {
            console.log('isMatch', NO_MATCH);
            setErrorConfirmPassword(NO_MATCH);
            setIsPasswordMatch(false);
        }
        else {
            console.log('isMatch', MATCH);
            setErrorConfirmPassword(MATCH);
            setIsPasswordMatch(true);
        }
    }

    function verifyPasswordCurrent(e) {
        setCurrentPassword(e.target.value);
        if (!e.target.value || e.target.value.length === 0) { setErrorPasswordCurrent(ENTER_CURRENT_PASSWORD); }
        else if (e.target.value.length > 0 && e.target.value.length < 8) setErrorPasswordCurrent(EIGHT_CHARACTERS);
        else if (isValidPassword(e.target.value)) {
            setIsCurrentPasswordValid(true);
            setErrorPasswordCurrent(VALID);
        } else {
            setErrorPasswordCurrent(REQ_DIGITS);
            setIsCurrentPasswordValid(false);
        }
        checkValid();
    }

    function verifyPassword(e) {
        setPassword(e.target.value);
        if (!e.target.value || e.target.value.length === 0) { setErrorPassword(ENTER_PASSWORD); }
        else if (e.target.value.length > 0 && e.target.value.length < 8) setErrorPassword(EIGHT_CHARACTERS);
        else if (isValidPassword(e.target.value)) {
            setIsPasswordValid(true);
            setErrorPassword(VALID);
        } else {
            setErrorPassword(REQ_DIGITS);
            setIsPasswordValid(false);
        }
        isMatch(e.target.value, confirmPassword);
        checkValid();
    }

    function verifyConfirmPassword(e) {
        setConfirmPassword(e.target.value);
        isMatch(password, e.target.value);
        checkValid();
    }

    const updateShowPassword = () => {
        if (showPassword === 'password') {
            setShowPassword('text');
            setShowPasswordText(HIDE_PASSWORD);
        }
        else
            if (showPassword === 'text') {
                setShowPassword('password');
                setShowPasswordText(SHOW_PASSWORD);
            }
    }

    if (!userDetails) getUserDetails();

    return (
        <React.Fragment>
            <MDBContainer style={styles} className="my-5">
                <MDBCard style={{ color: props.textIconColour, }}>
                    <MDBRow className='g-0'>
                        <MDBCol md='12' style={{
                            alignContent: "center",
                            height: '100%', width: '100%',
                            objectFit: 'contain'
                        }}>
                        {props.brandLogo ?
                            <img src={props.brandLogo} style={{
                                        height: 'auto', maxHeight: `${props.loginLogoMaxHeight}px`,
                                        'width': 'auto', maxWidth: `${props.loginLogoMaxWidth}px`,
                                objectFit: 'contain'
                            }} /> :
                            props.brand === 'refurbio' ?
                                        <RefurbLogo width={`${props.loginLogoMaxWidth}px`} /> :
                                props.brand === 'sysLiq' ?
                                            <SysLiqLogo width={`${props.loginLogoMaxWidth}px`} /> :
                                            <CnbLogo width={`${props.loginLogoMaxWidth}px`} />}                         
                        </MDBCol>
                    </MDBRow>

                    {rmaComp ? <>
                        <MDBRow className='g-0'>
                                <MDBCol md='12'>
                                    <div className='d-flex flex-row mt-2 '>
                                    <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faAddressCard} size="3x" className="me-3 mx-3" />
                                    <span style={{ color: props.textIconColour, }} className="h2 fw-bold mb-0">{`${ADDRESS_HEADER} ${isAddressValid ? '✔' : ''}`}</span>
                                </div>
                                <MDBCardBody className='d-flex flex-column'>
                                    <Address props={props} setAddress={updateAddress} currentAddress={rmaComp} user={user} />
                                    <MDBBtn className={btnClass} color={props.buttonColour} size='lg'
                                        disabled={!isAddressValid} onClick={update}>Update Address Details</MDBBtn>
                                </MDBCardBody>
                                </MDBCol>
                            </MDBRow>
                        </> : null}

                    <MDBRow className='g-0'>
                        <MDBCol md='6'>
                            <div className='d-flex flex-row mt-2 '>
                                <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faEnvelope} size="3x" className="me-3 mx-3" />
                                <span style={{ color: props.textIconColour, }} className="h1 fw-bold mb-0">Contact Email</span>
                            </div>
                            <MDBCardBody className='d-flex flex-column'>
                                <MDBInput wrapperClass='mb-4' label='Email address'
                                    id='formControlEmail' type='email' size="lg" onChange={updateEmail}
                                    value={rmaComp && rmaComp.Email ? rmaComp.Email: emailAddress}>
                                    <div id='emailErrorLabel' className='form-helper'>
                                        {errorEmail}
                                    </div>
                                </MDBInput>
                                <MDBBtn className={btnClass} color={props.buttonColour} size='lg'
                                    disabled={errorEmail} onClick={saveEmail}>Update Contact Email</MDBBtn>
                            </MDBCardBody>

                        </MDBCol>
                        <MDBCol md='6'>
                            <div className='d-flex flex-row mt-2 '>
                                <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faUserShield} size="3x" className="me-3 mx-3" />
                                <span style={{ color: props.textIconColour, }} className="h2 fw-bold mb-0">{`${SECURITY_HEADER} ${isPasswordValid && isPasswordMatch ? '✔' : ''}`}</span>
                            </div>
                            <MDBCardBody className='d-flex flex-column'>
                                <a href="#!" onClick={updateShowPassword} className="small text-muted text-end">{showPasswordText}</a>

                                <MDBInput
                                    wrapperClass='mb-4'
                                    aria-describedby='passwordCurrentErrorLabel'
                                    label='Current Password'
                                    id='formPasswordCurrent'
                                    onChange={verifyPasswordCurrent}
                                    type={showPassword} size="lg" style={errorPasswordCurrent !== VALID ? props.errorStyle : null} >
                                    <div id='passwordCurrentErrorLabel' className='form-helper'>
                                        {errorPasswordCurrent}
                                    </div>
                                </MDBInput>

                                <MDBInput
                                    wrapperClass='mb-4'
                                    aria-describedby='passwordErrorLabel'
                                    label='Password'
                                    id='formPassword'
                                    onChange={verifyPassword}
                                    type={showPassword} size="lg" style={errorPassword !== VALID ? props.errorStyle : null} >
                                    <div id='passwordErrorLabel' className='form-helper'>
                                        {errorPassword}
                                    </div>
                                </MDBInput>

                                <MDBInput
                                    wrapperClass='mb-4'
                                    aria-describedby='passwordConfirmErrorLabel'
                                    label='Confirm Password'
                                    id='formConfirmPassword'
                                    onChange={verifyConfirmPassword}
                                    type={showPassword} size="lg" style={errorConfirmPassword !== MATCH ? props.errorStyle : null}  >
                                    <div id='passwordConfirmErrorLabel' className='form-helper'>
                                        {errorConfirmPassword}
                                    </div>
                                </MDBInput>

                                <MDBBtn className={btnClass} color={props.buttonColour} size='lg' disabled={!isValid} onClick={updatePassword}>Update Password</MDBBtn>

                            </MDBCardBody>
                        </MDBCol>
                        <MDBCol md='6'>
                            <div className='d-flex flex-row mt-2 '>
                                <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faUser} size="3x" className="me-3 mx-3" />
                                <span style={{ color: props.textIconColour, }} className="h2 fw-bold mb-0">{`${USER_HEADER} ${isUserValid ? '✔' : ''}`}</span>
                            </div>
                            <MDBCardBody className='d-flex flex-column'>
                                {userDetails?
                                    <ManageUserDetails props={props} user={user} userDetails={userDetails} setIsUserValid={setIsUserValid} setNavName={setNavName} />
                                    : null}
                            </MDBCardBody>
                        </MDBCol>
                    </MDBRow>
                    {props.isAdpCustomer ?
                        <MDBRow className='g-0'>
                            <MDBCol md='12'>
                                <div className='d-flex flex-row mt-2 '>
                                    <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faFileSignature} size="3x" className="me-3 mx-3" />
                                    <span className="h2 fw-bold mb-0">{`Agreement`}</span>
                                </div>
                                <MDBCardBody className='d-flex flex-column'>
                                    <MDBBtn className={btnClass} color={props.buttonColour} size='lg' onClick={clickShow}>{showAgreement ? 'Hide' : 'Show'} Agreement</MDBBtn>

                                    {showAgreement && agreement ? <div >
                                        <ViewPDF props={props} pdf={agreement} />
                                    </div> : null}
                                </MDBCardBody>
                            </MDBCol>
                        </MDBRow> : null}
                </MDBCard>
            </MDBContainer>
        </React.Fragment>
    );
}
export default Manage;