import React, { useState, useEffect } from "react";

import Dropdown from "../categories/CategoryDropdown";

import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    //MDBCardImage,
    MDBRow,
    MDBCol,
    //MDBIcon,
    MDBInput
} from 'mdb-react-ui-kit';
const NAME_TOO_LONG_ERROR = 'Name should be 100 characters or less';
const NAME_TOO_SHORT_ERROR = 'Name should be 2 or more characters';
const languageOptions = [{ ID: "ENG", Value: 'English' }, { ID: "FRE", Value: 'French' },];
const accessOptions = [{ ID: "owner", Value: 'owner' }, { ID: "full", Value: 'full' },];

const ManageUserDetails = ({ props, user, userDetails, setIsUserValid, setNavName}) => {
    console.log('userDetails', userDetails);
    const [preferredLanguage, setPreferredLanguage] = useState({ ID: 'ENG', Value: 'English' });
    const [userAccess, setUserAccess] = useState({ ID: 'full', Value: 'full' });
    const [fullName, setFullName] = useState(userDetails && userDetails.FullName ? userDetails.FullName : null);
    const [fullNameError, setFullNameError] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [newUserDetails, setNewUserDetails] = useState(null);

    let btnClass = `mb-4 px-5 ${(props.doOutlineButton ? `btn-outline-${props.buttonColour}` : '')}`;

    useEffect(() => { updateDetails(); }, [fullNameError, fullName,preferredLanguage]);

    const updateFullName = (e) => {
        console.log(`updateFullName: ${e.target.value}`);
        setFullName(e.target.value);
        if (e.target.value.length > 100) {
            setFullNameError(NAME_TOO_LONG_ERROR);
            return;
        } else if (e.target.value.length < 2) {
            setFullNameError(NAME_TOO_SHORT_ERROR);
            return;
        } else {
            setFullNameError(null);
        }
        updateDetails();
    }

    const updatePreferredLanguage = (id, name) => {
        console.log(`updatePreferredLanguage: ${id} ${name}`);
        setPreferredLanguage({ ID: id, Value: name });
    };

    const updateUserAccess = (id, name) => {
        console.log(`updateUserAccess: ${id} ${name}`);
        setUserAccess({ ID: id, Value: name });
    }

    const updateDetails = () => {
        if (fullName && !fullNameError) {
            setNewUserDetails({
                UserName: userDetails.UserName,
                FullName: fullName,
                PreferredLanguage: preferredLanguage,
                UserAccess: userAccess
            });
            setIsValid(true);
            setIsUserValid(true);
        }
        else {
            setNewUserDetails(null);
            setIsValid(false);
            setIsUserValid(false);
        }
    }

    const update = () => {
        if (!isValid) return;
 
        const requestOptions = {
            method: "post",
            headers: props.header,
            redirect: "follow",
            changeOrigin: true,
            body: JSON.stringify(
                {
                    UserName: user.UserName,
                    Token: user.Token,
                    User: user,
                    NewUserDetails: newUserDetails,
                    Site: props.brand,
                    RegisteredURL: window.location.href,
                }
            ),
        };

        fetch(`${props.baseUrl}User/update`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(`[ManageUserDetails][update]> Result: ${result}`);
                /* Validate Entries: Confirm Serial number is valid for account, get place of purchase */
                result = JSON.parse(result);
                if (result && result.status && result.status === 200) {
                    console.log(`[ManageUserDetails][update][SUCCESS]> ${result}`);
                    alert(result.message);

                    /* Set Updated details back to user in updating own details */
                    if (user.UserName === userDetails.UserName) {                        
                        setNavName(newUserDetails.FullName);
                    }
                } else {
                    console.log(`[ManageUserDetails][update][FAILED]> ${result}`);
                    if (result && result.message) {
                        alert(result.message);
                    } else {
                        alert('There appears to have been an issue with updating the details...');
                    }
                }
            })
            .catch((error) => console.error('[ManageUserDetails][update][ERROR]> ' + error));
    }

    return (
        <React.Fragment>

            <form id="frmUserDetails">
                <MDBInput wrapperClass='mb-4' maxLength={100}
                    onChange={updateFullName} label='Full Name *' id='formControlName'
                    defaultValue={userDetails && userDetails.FullName ? userDetails.FullName : null}
                    type='text' size="lg" style={fullNameError ? props.errorStyle : null}>
                    <div id='formNameErrorLabel' className='form-helper'>
                        {fullNameError}
                    </div>
                </MDBInput>
                <Dropdown options={languageOptions} updateChoice={updatePreferredLanguage} chooseWhat={'Preferred Language'} value={userDetails && userDetails.Language ? userDetails.Language : 'English'} />
                <Dropdown options={accessOptions} updateChoice={updateUserAccess} chooseWhat={'User Access'} value={userDetails && userDetails.UserAccess ? userDetails.UserAccess : 'full'} />


            </form>
            <MDBBtn className={btnClass} color={props.buttonColour} size='lg'
                disabled={!isValid} onClick={update}>Update User Details</MDBBtn>
        </React.Fragment>
    );
}
export default ManageUserDetails;