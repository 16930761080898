import React, { useState } from "react";
//import styles from "./CheckWarranty.module.css";
import { getIcon, getProdName, getWarrantyDetails, NOT_ADP } from "../../service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {
    MDBBtn,
    //MDBIcon,
    MDBInput,
    MDBAccordion,
    MDBAccordionItem
} from 'mdb-react-ui-kit';

const CHECK_BUTTON_TEXT = 'Check Warranty';
const SECURRANTY_URL = 'https://securranty.com/';

const CheckWarranty = ({ props,}) => {
    const [errorSerial, setErrorSerial] = useState(null);
    const [serialNumber, setSerialNumber] = useState(null);
    const [product, setProduct] = useState(null);
    const [doShowSeccuranty, setDoShowSeccuranty] = useState(false);
    let btnClass = `mb-4 px-5 ${(props.doOutlineButton ? `btn-outline-${props.buttonColour}`  : '')}`;

    /**
     * Used for onChange in text box which feeds argument, checks validity and sets state
     * @param {any} e
     */
    function updateSerial(e) {
        setSerialNumber(e.target.value);
        const serialRegex = /^[A-Za-z0-9]+$/;
        console.log(`updateSerial: ${e.target.value}`, 'Pass RegEx:', (serialRegex.test(e.target.value)), 'Pass Length: ', !(e.target.value.length < 5));
        if (!serialRegex.test(e.target.value) || e.target.value.length < 4) {
            setErrorSerial('Please enter a valid serial number');
        } else {
            setSerialNumber(e.target.value);
            setErrorSerial(null);
            return true;
        }
    }
    const handeKeyPress  = (e) => {
        if (e.key === 'Enter') {
            validate();
        } else if (doShowSeccuranty) {
            setDoShowSeccuranty(false);
        }
    }

    const validate = () => {
        if (serialNumber && !errorSerial) {
            setDoShowSeccuranty(false);
            const requestOptions = {
                method: "GET",
                headers: props.header,
                redirect: "follow",
                changeOrigin: true
            };

            fetch(`${props.baseUrl}product/${serialNumber}`, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    /* Validate Entries: Confirm Serial number is valid for account, get place of purchase */
                    result = JSON.parse(result);
                    if (result && result.status && result.status === 200) {
                        console.log(`[CheckWarranty][validate][validationSection][SUCCESS]>`, result);
                        /* Check Warranty Details */
                        getWarrantyDetails(result.product);

                        setProduct(result.product);

                        console.log(`[CheckWarranty][validate][validationSection][SUCCESS]>`, props.isAdpCustomer, !result.product.IsADP, props.isAdpCustomer && !result.product.IsADP);
                        setDoShowSeccuranty(props.isAdpCustomer && !result.product.IsADP);
                    } else {
                        console.log(`[CheckWarranty][validate][validationSection][FAILED]> ${result}`);
                        if (result && result.message) {
                            setErrorSerial(result.message);
                            setDoShowSeccuranty(props.isAdpCustomer);
                        } else {
                            setErrorSerial('There was an issue validating the serial number...');
                            setDoShowSeccuranty(props.isAdpCustomer);
                        }
                    }
                })
                .catch((error) => {
                    console.error('[CheckWarranty][validate][validationSection][ERROR]> There was an issue checking the serial number: ' + error);
                    setErrorSerial(error);
                });
        }
    }

    return (
        <React.Fragment>
            <h4 className="fw-normal my-4 pb-1"  style={{ color: props.textIconColour, letterSpacing: '1px' }}>Quick check a Warranty</h4>
            <MDBInput maxLength={50} wrapperClass='mb-4' label='Serial Number of Unit' id='formControlSerial'
                onKeyPress={handeKeyPress}
                type='text' size="lg" onChange={updateSerial}                
                defaultValue={serialNumber?serialNumber:''}
                style={errorSerial ? props.errorStyle : null}
            >
                <div id='serialErrorLabel' className='form-helper'>
                    {errorSerial}
                </div>
            </MDBInput>
            <MDBBtn className={btnClass} color={props.buttonColour} size='lg' onClick={validate}>{CHECK_BUTTON_TEXT}</MDBBtn>
            {
                doShowSeccuranty ?
                <>
                    <p>{'If you purchased your ADP coverage prior to August 1st 2024 for '}<b>{serialNumber}</b> {' your coverage may be found here: '} <a href={SECURRANTY_URL} target="_blank" rel="noopener noreferrer"> {'Securranty'}</a> </p>
                </> : null
            }
            <MDBAccordion initialActive={1}>
                {product ? <MDBAccordionItem collapseId={1} headerTitle={<>
                    <FontAwesomeIcon style={{ color: (product.IsCovered ? props.passIconColour : props.failIconColour), }} icon={(product.IsCovered ? faCheckCircle : faTimesCircle)} size="2x" className="me-3 mx-3" />&nbsp; {product.SerialNumber}</>}>
                    <div className='d-flex flex-row mt-2 mb-5' >
                        <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={getIcon(product.ProductType)} size="3x" className="me-3" />
                        <span style={{ color: props.textIconColour, }}  className="h1 fw-bold mb-0">{`${getProdName(product.ProductType)}`}</span>
                        <FontAwesomeIcon style={{ color: (product.IsCovered ? props.passIconColour : props.failIconColour), }} icon={(product.IsCovered ? faCheckCircle : faTimesCircle)} size="3x" className="me-3 mx-3" />
                    </div>
                    {product.IsADP && !NOT_ADP.includes(product.WarrantyLength.toString()) && props.isAdpCustomer ? <img src={props.shieldGuardImage} alt='CNB Shield Guard Logo' style={{
                        height: 'auto', maxHeight: '100px',
                        'width': 'auto',
                        objectFit: 'contain'
                    }} />: null}
                    {product.RemainingCoverage && product.IsCovered ? <p><b>{'Remaining Coverage: '}</b>{`${product.RemainingCoverage} days`}</p> : null}
                    {product.OrderNumber? <p><b>{'Invoice Number: '}</b>{product.OrderNumber}</p> : null}
                    {product.SerialNumber ? <p><b>{'Serial Number: '}</b>{product.SerialNumber}</p> : null}
                    {product.PlaceOfPurchase ? <p><b>{'Place of Purchase: '}</b>{product.PlaceOfPurchase}</p> : null}
                    <p><b>{'Product:'}</b> {product.Description}</p>
                    <p><b>{'UPC:'}</b> {product.UPC}</p>
                </MDBAccordionItem> : null}
            </MDBAccordion>
        </React.Fragment>
    )
};
/*
                    WarrantyDays: item.WarrantyDays,
                    SerialNumber: item.ManufacturerSN,
                    WarrantyMonths: item.WarrantyMonths,
                    WarrantyLength: item.WarrantyLength,
                    OrderNumber: item.OrderNo,
                    IsCovered: item.WarrantyDays && item.WarrantyDays > 0 ? true : false,
                    isADP: item.WarrantyLength && item.WarrantyLength > 0 && item.WarrantyLength != NOT_ADP ? true : false,
                    PlaceOfPurchase: item.VendorName,
                    CreatedBy: item.AssignedTechnician,
                    ProductType: item.ProductType,
                    Description: item.OrderDetail,
                    UPC: item.UPC,
                    MAR: item.MAR,
 */

export default CheckWarranty;
