import React, { useState } from "react";
import {
    MDBBtn,
    MDBInput
} from 'mdb-react-ui-kit';
const regexConfirmPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&?]).{8,}$/;
const REQ_DIGITS = 'At least one lowercase letter, one uppercase letter, one number, and one special character';
const SHOW_PASSWORD = 'Show Password';
const HIDE_PASSWORD = 'Hide Password';
const ENTER_PASSWORD = 'Please Enter a Password';
const EIGHT_CHARACTERS = 'Password must be at least 8 Characters';
const VALID = 'Password Valid ✔';
const MATCH = 'Passwords Match ✔';
const NO_MATCH = "Passwords don't Match!";
const SETUP_PASSWORD = 'Set up your Account Password';
const RESET_PASSWORD = 'Reset your Account Password';

const ResetPassword = ({ props, setActive }) => {
    const [showPassword, setShowPassword] = useState('password');
    const [password, setPassword] = useState(null);
    const [showPasswordText, setShowPasswordText] = useState(SHOW_PASSWORD);
    const [errorPassword, setErrorPassword] = useState(ENTER_PASSWORD);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isPasswordMatch, setIsPasswordMatch] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
    const queryParameters = new URLSearchParams(decodeURIComponent(window.location.search));
    const username = queryParameters.get("u");
    const action = queryParameters.get("a");
    const token = queryParameters.get("t");
    let btnClass = `mb-4 px-5 ${(props.doOutlineButton ? `btn-outline-${props.buttonColour}` : '')}`;

    let actionText = action === 'NewPassword' ? SETUP_PASSWORD : RESET_PASSWORD;

    function isMatch(pass, confPass) {
        if (confPass !== pass) {
            console.log('isMatch', NO_MATCH);
            setErrorConfirmPassword(NO_MATCH);
            setIsPasswordMatch(false);
        }
        else {
            console.log('isMatch', MATCH);
            setErrorConfirmPassword(MATCH);
            setIsPasswordMatch(true);
        }
    }

    function verifyPassword(e) {
        setPassword(e.target.value);
        if (!e.target.value || e.target.value.length === 0) { setErrorPassword(ENTER_PASSWORD); }
        else if (e.target.value.length > 0 && e.target.value.length < 8) setErrorPassword(EIGHT_CHARACTERS);
        else if (isValidPassword(e.target.value)) {
            setIsPasswordValid(true);
            setErrorPassword(VALID);
        } else {
            setErrorPassword(REQ_DIGITS);
            setIsPasswordValid(false);
        }
        isMatch(e.target.value, confirmPassword);
    }

    function verifyConfirmPassword(e) {
        setConfirmPassword(e.target.value);
        isMatch(password, e.target.value);
    }

    const isValidPassword = (password) => {
        return regexConfirmPassword.test(password);
    }
    const updateShowPassword = () => {
        if (showPassword === 'password') {
            setShowPassword('text');
            setShowPasswordText(HIDE_PASSWORD);
        }
        else
            if (showPassword === 'text') {
                setShowPassword('password');
                setShowPasswordText(SHOW_PASSWORD);
            }
    }

    const updatePassword = () => {
        console.log('[ResetPassword][updatePassword]> ', username, token, action, isPasswordValid, isPasswordMatch);
        if (username && token && action && isPasswordValid && isPasswordMatch) {
            let body = JSON.stringify({
                UserName: username,
                ActionToken: token,
                Action: action,
                Site: props.brand,
                ReqURL: window.location.href,
                Password: password,
            });

            const requestOptions = {
                method: "post",
                headers: props.header,
                redirect: "follow",
                changeOrigin: true,
                body: body,
            };

            fetch(`${props.baseUrl}User/Password/Reset/Update`, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    console.log(`[ResetPassword][updatePassword]> Result: ${result}`);

                    result = JSON.parse(result);
                    if (result && result.status && result.status === 200) {
                        console.log(`[ResetPassword][updatePassword][SUCCESS]> ${result}`);
                        alert(result.message);
                        removeUrlParams();
                        setActive('Login');
                    } else {
                        console.log(`[ResetPassword][updatePassword][FAILED]> ${result}`);
                        if (result && result.message && result.status !== 200) {
                            alert(result.message);
                        } else {
                            alert('There was an issue sending the request.');
                        }
                    }
                })
                .catch((error) => console.error('[ResetPassword][updatePassword][ERROR]> There was an issue resetting password: ' + error));
        }
    }

    const removeUrlParams = () => {
        window.location.href = window.location.href.replace(window.location.search, '');
    }

    return (
        <React.Fragment>
            <h5 className="fw-normal my-4 pb-3" style={{ color: props.textIconColour, letterSpacing: '1px' }}>{actionText}</h5>
            <form>
                <a href="#!" onClick={updateShowPassword} className="small text-muted text-end">{showPasswordText}</a>
                <MDBInput
                    wrapperClass='mb-4'
                    aria-describedby='passwordErrorLabel'
                    label='Password'
                    id='formPassword'
                    onChange={verifyPassword}
                    type={showPassword} size="lg" style={errorPassword !== VALID ? props.errorStyle : null} >
                    <div id='passwordErrorLabel' className='form-helper'>
                        {errorPassword}
                    </div>
                </MDBInput>

                <MDBInput
                    wrapperClass='mb-4'
                    aria-describedby='passwordConfirmErrorLabel'
                    label='Confirm Password'
                    id='formConfirmPassword'
                    onChange={verifyConfirmPassword}
                    type={showPassword} size="lg" style={errorConfirmPassword !== MATCH ? props.errorStyle : null}  >
                    <div id='passwordConfirmErrorLabel' className='form-helper'>
                        {errorConfirmPassword}
                    </div>
                </MDBInput>
            </form>
            <MDBBtn className={btnClass} color={props.buttonColour} onClick={updatePassword} size='lg'>Update Password</MDBBtn>
        </React.Fragment>
    )
};

export default ResetPassword;
