// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background-color: #9A616D;
}

.Login_brandHeaderImage__kJp5O {
    height: auto;
    max-height: 300px;
    width: 100%;
    max-width: 500px;
    object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/components/login/Login.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":["body {\r\n    background-color: #9A616D;\r\n}\r\n\r\n.brandHeaderImage {\r\n    height: auto;\r\n    max-height: 300px;\r\n    width: 100%;\r\n    max-width: 500px;\r\n    object-fit: contain;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brandHeaderImage": `Login_brandHeaderImage__kJp5O`
};
export default ___CSS_LOADER_EXPORT___;
