import React from "react";
import RmaStatusQuickCheck from './rma/RmaStatusQuickCheck';
import CheckWarranty from '../validation/CheckWarranty';
import styles from "./Home.module.css";
import { ReactComponent as CnbLogo } from '../../CnbLogo.svg';
import { ReactComponent as RefurbLogo } from '../../Refurbio.svg';
import { ReactComponent as SysLiqLogo } from '../../SysLiqLogo.svg';
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    //MDBCardImage,
    MDBRow,
    MDBCol,
    MDBCardTitle,
    MDBCardText,
    //MDBIcon,
    //MDBInput
} from 'mdb-react-ui-kit';

import { faShieldAlt, faSearch, faFolderOpen, faFolderPlus, faUser, faDoorOpen, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Home = ({ props, setActive, getRmas, getRmaComp, updateShowLogin, manageUsers }) => {
    let btnClass = `mb-4 px-5 ${(props.doOutlineButton ? `btn-outline-${props.buttonColour}` : '')}`;
    return (
        <React.Fragment>
            <MDBContainer style={styles} className="my-3">
                <MDBRow className='g-0'>
                    <MDBCol md='12' style={{
                        alignContent: "center",
                        height: '100%', width: '100%',
                        objectFit: 'contain'
                    }}>

                        <MDBCard shadow='1' border='secondary' background='white'>
                            <MDBCardBody style={{ color: props.textIconColour, }}>

                                {props.brandLogo && !props.doShowBanner ?
                                    <img src={props.brandLogo} style={{
                                        height: 'auto', maxHeight: `${props.loginLogoMaxHeight}px`,
                                        'width': 'auto', maxWidth: `${props.loginLogoMaxWidth}px`,
                                        objectFit: 'contain'
                                    }} /> :
                                    props.bannerImage && props.doShowBanner ?
                                        <img src={props.bannerImage} style={{
                                            height: 'auto', maxHeight: `${props.loginLogoMaxHeight}px`,
                                            'width': 'auto',
                                            objectFit: 'contain'
                                        }} /> :
                                        props.brand === 'refurbio' ?
                                            <RefurbLogo width={`${props.loginLogoMaxWidth}px`} /> :
                                            props.brand === 'sysLiq' ?
                                                <SysLiqLogo width={`${props.loginLogoMaxWidth}px`} /> :
                                                <CnbLogo width={`${props.loginLogoMaxWidth}px`} />}
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='row-cols-1 row-cols-md-2 g-4 my-2'>
                    <MDBCol>
                        <MDBCard shadow='1' border='secondary' background='white'>
                            <MDBCardBody style={{ color: props.textIconColour,  }}>
                                <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faSearch} size="6x" />
                                <RmaStatusQuickCheck props={props} />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol>
                        <MDBCard shadow='1' border='secondary' background='white'>
                            <MDBCardBody style={{ color: props.textIconColour, }}>
                                <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faShieldAlt} size="6x" />
                                <CheckWarranty props={props} />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='row-cols-1 row-cols-md-2 g-4 my-2'>
                    <MDBCol>
                        <MDBCard shadow='1' border='secondary' background='white'>
                            <MDBCardBody style={{ color: props.textIconColour, }}>
                                <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faFolderOpen} size="6x" />
                                <MDBCardTitle style={{ color: props.textIconColour, }}>View and Update RMAs</MDBCardTitle>
                                <MDBCardText style={{ color: props.textIconColour, }}>
                                    View all RMAs attached to your account. Add additional products to 'Open' and 'Requested' RMAs.
                                </MDBCardText>
                                <MDBBtn className={btnClass} color={props.buttonColour} size='lg' onClick={getRmas}>{'View and Update RMAs'}</MDBBtn>
                            </MDBCardBody>
                        </MDBCard>

                    </MDBCol>
                    <MDBCol>
                        <MDBCard shadow='1' border='secondary' background='white'>
                            <MDBCardBody style={{ color: props.textIconColour, }}>
                                <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faFolderPlus} size="6x" />
                                <MDBCardTitle style={{ color: props.textIconColour, }} >Submit a new RMA Claim</MDBCardTitle>
                                <MDBCardText style={{ color: props.textIconColour, }}>
                                    Have the Serial Numbers of the products you would like to submit ready and start a new RMA claim.
                                </MDBCardText>
                                <MDBBtn className={btnClass} color={props.buttonColour} size='lg' onClick={() => { setActive('Create') }}>{'Create a New RMA'}</MDBBtn>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='row-cols-1 row-cols-md-2 g-4 my-2'>
                    <MDBCol>
                        <MDBCard shadow='1' border='secondary' background='white'>
                            <MDBCardBody style={{ color: props.textIconColour, }}>
                                <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faUser} size="6x" className="mb-2" />
                                <MDBCardTitle style={{ color: props.textIconColour, }}>Manage Account Details</MDBCardTitle>
                                <MDBCardText style={{ color: props.textIconColour, }}>
                                    Update your shipping address, change your main contact e-mail, and manage your Password
                                </MDBCardText>
                                <MDBBtn className={btnClass} color={props.buttonColour} size='lg' onClick={getRmaComp}>{'Manage Account Details'}</MDBBtn>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol>
                        <MDBCard shadow='1' border='secondary' background='white'>
                            <MDBCardBody style={{ color: props.textIconColour, }}>
                                <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faUserFriends} size="6x" className="mb-2" />
                                <MDBCardTitle style={{ color: props.textIconColour, }}>Manage Additional Users</MDBCardTitle>
                                <MDBCardText style={{ color: props.textIconColour, }}>
                                    Add and Manage Additional Users, Request Password Resets for them, Update User Access, and Change Names
                                </MDBCardText>
                                <MDBBtn className={btnClass} color={props.buttonColour} size='lg' onClick={manageUsers}>{'Manage Additional Users'}</MDBBtn>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='row-cols-1 row-cols-md-2 g-4 my-2'>   
                    <MDBCol>
                        <MDBCard shadow='1' border='secondary' background='white'>
                            <MDBCardBody style={{ color: props.textIconColour, }}>
                                <FontAwesomeIcon style={{ color: props.textIconColour, }} icon={faDoorOpen} size="6x" className="mb-4" />
                                <MDBCardTitle style={{ color: props.textIconColour, }}>Logout</MDBCardTitle>
                                <MDBCardText style={{ color: props.textIconColour, }}>
                                    Logout of your account

                                </MDBCardText>
                                <MDBBtn className={btnClass} color={props.buttonColour} size='lg' onClick={() => updateShowLogin(true)}>{'Logout'}</MDBBtn>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </React.Fragment>);
}

export default Home;