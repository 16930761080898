import React, { useState, } from "react";
import styles from "./Login.module.css";
import ForgotPassword from "../user/ForgotPassword";
import ResetPassword from "../user/ResetPassword";
import RmaStatusQuickCheck from "../main/rma/RmaStatusQuickCheck";
import { ReactComponent as CnbLogo } from '../../CnbLogo.svg';
import { ReactComponent as RefurbLogo } from '../../Refurbio.svg';
import { ReactComponent as SysLiqLogo } from '../../SysLiqLogo.svg';
//import { getImageSrc, camelize } from "../../service";
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBRow,
    MDBCol,
    //MDBIcon,
    MDBInput
} from 'mdb-react-ui-kit';

const SHOW_PASSWORD = 'Show Password';
const HIDE_PASSWORD = 'Hide Password';
const ENTER_PASSWORD = 'Please Enter a Password';
const EIGHT_CHARACTERS = 'Password must be at least 8 Characters';
const INCORRECT_USERNAME = 'Incorrect Username!';
const INCORRECT_PASSWORD = 'Incorrect Password!';
const Login = ({ props, clickShowValidation, setUser }) => {
    const [active, setActive] = useState('Login');
    const [showPassword, setShowPassword] = useState('password');
    const [password, setPassword] = useState(null);
    const [showPasswordText, setShowPasswordText] = useState(SHOW_PASSWORD);
    const [errorPassword, setErrorPassword] = useState(ENTER_PASSWORD);
    const [userName, setUserName] = useState(null);
    const [errorEmail, setErrorEmail] = useState(null);
    const [actionCompleted, setActionCompleted] = useState(false);
    const [onLoad, setOnLoad] = useState(true);
    const queryParameters = new URLSearchParams(decodeURIComponent(window.location.search));
    const username = queryParameters.get("u");
    const action = queryParameters.get("a");
    const token = queryParameters.get("t");

    let btnClass = `mb-4 px-5 ${(props.doOutlineButton ? `btn-outline-${props.buttonColour}` : '')}`;
    console.log(window.location.href, username, action, token);

    const checkForUserAction = () => {
        if (username && action && token && !actionCompleted) {
            console.log(`[Login][checkForUserAction]> Setting Action ${action}`);
            setActive(action);
        } else {
            console.log('[Login][checkForUserAction]> No user action request.');
        }
    }

    if (onLoad) {
        checkForUserAction();
        setOnLoad(false);
    }

    const updateShowPassword = () => {
        if (showPassword === 'password') {
            setShowPassword('text');
            setShowPasswordText(HIDE_PASSWORD);
        }
        else if (showPassword === 'text') {
            setShowPassword('password');
            setShowPasswordText(SHOW_PASSWORD);
        }
    }

    const setShowRefreshPassword = () => {
        if (active !== 'ForgotPassword' && active !== 'ResetPassword') {
            setActive('ForgotPassword');
        } else {
            setActive('Login');
        }
    }

    const handeKeyPressLogin = (e) => {
        if (e.key === 'Enter') {
            loginUser();
        }
    }
    /**
     * Used for onChange in text box which feeds argument, checks validity and sets state
     * @param {any} e
     */
    function updateEmail(e) {
        setUserName(e.target.value);
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        console.log(`updateEmail: ${e.target.value}`, 'Pass RegEx:', (emailRegex.test(e.target.value)), 'Pass Length: ', !(e.target.value.length < 5));
        if (!emailRegex.test(e.target.value)) {
            setErrorEmail('Please enter a valid email address');
        } else {
            setErrorEmail(null);
            return true;
        }
    }

    function verifyPassword(e) {
        setPassword(e.target.value);
        if (!e.target.value || e.target.value.length === 0) { setErrorPassword(ENTER_PASSWORD); }
        else if (e.target.value.length > 0 && e.target.value.length < 8) setErrorPassword(EIGHT_CHARACTERS);
        else { setErrorPassword(null);}
    }

    const loginUser = () => {        
        if (userName && password) {
            let body = JSON.stringify({
                UserName: userName,
                Password: password,
            });

            const requestOptions = {
                method: "post",
                headers: props.header,
                redirect: "follow",
                changeOrigin: true,
                body: body,
            };

            fetch(`${props.baseUrl}Login`, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    setErrorEmail(null);
                    console.log(`[Login][loginUser]> Result: ${result}`);
                    /* Validate Entries: Confirm Serial number is valid for account, get place of purchase */
                    result = JSON.parse(result);
                    if (result && result.status && result.status === 200) {
                        console.log(`[Login][loginUser][SUCCESS]> ${result}`);
                        setUser(result.user);
                    } else {
                        console.log(`[Login][loginUser][FAILED]> ${result}`);
                        if (result && result.message && result.status !== 200) {
                            if (INCORRECT_USERNAME === result.message) setErrorEmail(result.message);
                            else if (INCORRECT_PASSWORD === result.message) setErrorPassword(result.message);
                            else setErrorEmail(result.message);
                        } else {
                        }
                    }
                })
                .catch((error) => console.error('[Login][loginUser][ERROR]> There was an issue logging in: ' + error));
        }
    }

    return (
        <React.Fragment>
            <MDBContainer style={styles} className="my-5">
                <MDBCard>
                    <MDBRow className='g-0'>
                        <MDBCol md='6'>
                            <MDBCardImage src={props.image} alt="login form" className='rounded-start img-fluid' style={{
                                height: '100%', 'width': 'auto', 
                                 objectFit: 'cover' }}/>
                        </MDBCol>
                        <MDBCol md='6'>
                            <MDBCardBody className='d-flex flex-column'>
                                <div style={{
                                    alignContent: "center", maxHeight: `${props.loginLogoMaxHeight}px`,
                                    height: '100%', width: '100%', maxWidth: `${props.loginLogoMaxWidth}px`,
                                    objectFit: 'contain'
                                }} >
                                    {props.brandLogo ?
                                        <img src={props.brandLogo} style={{
                                            height: 'auto', maxHeight: `${props.loginLogoMaxHeight}px`,
                                            'width': 'auto', maxWidth: `${props.loginLogoMaxWidth}px`,
                                    objectFit: 'contain'
                                    }} /> :
                                        props.brand === 'refurbio' ?
                                            <RefurbLogo width={`100%`} /> :
                                            props.brand === 'sysLiq' ?
                                                <SysLiqLogo width={`100%`} /> :
                                                <CnbLogo width={`100%`} />}
                                </div>

                                <RmaStatusQuickCheck props={props} />
                                <h5 className="fw-normal my-4 pb-3" style={{ color: props.textIconColour, letterSpacing: '1px' }}>Or</h5>
                                {active === 'ResetPassword' || active === 'NewPassword'  ?
                                    <>
                                        <ResetPassword props={props} setActive={ setActive} />
                                    </>
                                    : null}
                                {active === 'ForgotPassword' ?
                                    <>
                                        <ForgotPassword props={props} email={userName} />
                                    </>
                                    : null}
                                {active === 'Login' ?
                                    <>
                                        <h5 className="fw-normal my-4 pb-3" style={{ color: props.textIconColour, letterSpacing: '1px' }}>Sign into your account</h5>
                                        <form>
                                            <MDBInput wrapperClass='mb-4' label='Email address' id='formControlEmail'
                                                type='email' size="lg" onChange={updateEmail} style={errorEmail ? props.errorStyle : null} >
                                                <div id='emailErrorLabel' className='form-helper'>
                                                    {errorEmail}
                                                </div>
                                            </MDBInput>

                                            <a href="#!" onClick={updateShowPassword} className="small text-muted text-end">{showPasswordText}</a>
                                            <MDBInput
                                                wrapperClass='mb-4'
                                                aria-describedby='passwordErrorLabel'
                                                label='Password'
                                                id='formPassword'
                                                onChange={verifyPassword}
                                                onKeyPress={handeKeyPressLogin}
                                                type={showPassword} size="lg"
                                                style={errorPassword ? props.errorStyle : null}>
                                                <div id='passwordErrorLabel' className='form-helper'>
                                                    {errorPassword}
                                                </div>
                                            </MDBInput>
                                        </form>
                                        <MDBBtn className={btnClass} color={props.buttonColour} onClick={loginUser} size='lg'>Login</MDBBtn>
                                    </>:null}
                                <a className="small text-muted" onClick={setShowRefreshPassword} href="#!">{active === 'ForgotPassword' || active === 'ResetPassword' ? 'Back to Login' : 'Forgot password?'}</a>
                                <h3 className="mb-5 my-4">Don't have an account? <a onClick={() => clickShowValidation(true)} href="#!" >Register here</a></h3>
                                <div className='d-flex flex-row justify-content-start'>
                                    <a href="#!" className="small text-muted me-1">Terms of use.</a>
                                    <a href="#!" className="small text-muted">Privacy policy</a>
                                </div>
                            </MDBCardBody>
                        </MDBCol>
                    </MDBRow>
                </MDBCard>
            </MDBContainer>
        </React.Fragment>
    )
};

export default Login;
