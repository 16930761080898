import React, { useState } from "react";
import styles from "./Validation.module.css";
import Dropdown from "../categories/CategoryDropdown";
//import { getImageSrc, camelize } from "../../service";
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBInput
} from 'mdb-react-ui-kit';
const errorStyle = { color: 'red' };
const VALIDATION_SERIAL = 'Serial';
const VALIDATION_ORDER = 'Order';

const Validation = ({ clickShowValidation,  updateShowSignup, setEmailAddress, emailAddress,
    placeOfPurchase, setPlaceOfPurchase, serialNumber, setSerialNumber, categoryChoice, setCategoryChoice, setProduct, props }) => {
    
    //console.log(`categories: ${JSON.stringify(categories)}`);

    /* Set up states for validation */
    const [errorEmail, setErrorEmail] = useState(null);
    const [errorSerial, setErrorSerial] = useState(null);
    const [categories, setCategories] = useState(null);
    let btnClass = `mb-4 px-5 ${(props.doOutlineButton ? `btn-outline-${props.buttonColour}` : '')}`;

    /**
     * Takes ID and Name and sets the category choice state
     * @param {any} id
     * @param {any} name
     */
    const updateChoice = (id, name) => {
        console.log(`updateCategoryChoice: ${id} ${name}`);
        setCategoryChoice({ CategoryID: id, Category: name });
    };

    if (!categories) getCategories();

    function getCategories() {
        if (!categories) {
            let items = [];
            console.log(`Getting Categories...`);
            fetch(`${props.baseUrl}categories/`, props.requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    let parsedRes = JSON.parse(result);
                    for (var item of parsedRes.categories) {
                        if (!items.includes({ ID: item.CategoryID, Value: item.Category })) {
                            items.push({ ID: item.CategoryID, Value: item.Category });
                        }
                    }

                    setCategories(items);
                })
                .catch((error) => console.error('There was an issue creating the select items: ' + error));
        }
    }

    /**
     * Used for onChange in text box which feeds argument, checks validity and sets state
     * @param {any} e
     */
    function updateEmail(e) {
        setEmailAddress(e.target.value);
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        console.log(`updateEmail: ${e.target.value}`, 'Pass RegEx:', (emailRegex.test(e.target.value)), 'Pass Length: ', !(e.target.value.length < 5));
        if (!emailRegex.test(e.target.value)) {
            setErrorEmail('Please enter a valid email address');
        } else {
            setErrorEmail(null);
            return true;
        }        
    }

    /**
     * Used for onChange in text box which feeds argument, checks validity and sets state
     * @param {any} e
     */
    function updateSerial(e) {
        setSerialNumber(e.target.value);
        const serialRegex = /^[A-Za-z0-9]+$/;
        console.log(`updateSerial: ${e.target.value}`, 'Pass RegEx:', (serialRegex.test(e.target.value)),'Pass Length: ', !(e.target.value.length < 5));
        if (!serialRegex.test(e.target.value) || e.target.value.length < 4) {
            setErrorSerial(`Please enter a valid ${(props.isAdpCustomer ? VALIDATION_ORDER : VALIDATION_SERIAL)} number`);
        } else {
            setSerialNumber(e.target.value);
            setErrorSerial(null);
            return true;
        }
    }      

    const validate = () => {
        if (((!props.isAdpCustomer && categoryChoice) || props.isAdpCustomer) && !errorEmail && serialNumber) {
       
            const requestOptions = {
                method: "GET",
                headers: props.header,
                redirect: "follow",
                changeOrigin: true
            };
            console.log(`validate`, categoryChoice, emailAddress, serialNumber);

            fetch(`${props.baseUrl}UserName/${emailAddress}`, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    console.log(`[Validation][validate]> Result: ${result}`);
                    /* Validate Entries: Confirm Serial number is valid for account, get place of purchase */
                    result = JSON.parse(result);
                    if (result && result.status && result.status === 200) {
                        setErrorSerial(result.message);
                        setErrorEmail(result.message);
                    } else {
                        console.log(`[Validation][validate][PASSED]> ${result}`);
                        if (result && result.message && result.status === 400 && result.message === "Username Does Not Exist!") {
                            fetch(`${props.baseUrl}product/${(props.isAdpCustomer ? `order/${serialNumber}` : serialNumber)}`, requestOptions)
                                .then((response) => response.text())
                                .then((result) => {
                                    console.log(`[Validation][validate]> Result: ${result}`);
                                    /* Validate Entries: Confirm Serial number is valid for account, get place of purchase */
                                    result = JSON.parse(result);
                                    if (result && result.status && result.status === 200) {
                                        console.log(`[Validation][validate][SUCCESS]> ${result}`);
                                        if (props.isAdpCustomer) {
                                            if (!result.product.IsADP) {
                                                setErrorSerial(`The order ${serialNumber} does not appear to have ADP coverage`);
                                            } else {
                                                setProduct(result.product);
                                                updateShowSignup(true);
                                            }
                                        } else {
                                            setProduct(result.product);
                                            updateShowSignup(true);
                                        }
                                    } else {
                                        console.log(`[Validation][validate][FAILED]> ${result}`);
                                        if (result && result.message) {
                                            setErrorSerial(result.message);
                                        } else {
                                            setErrorSerial(`There was an issue validating the ${(props.isAdpCustomer ? VALIDATION_ORDER : VALIDATION_SERIAL)} number...`);
                                        }
                                    }
                                })
                                .catch((error) => console.error(`[Validation][validate][ERROR]> There was an issue checking the ${(props.isAdpCustomer ? VALIDATION_ORDER : VALIDATION_SERIAL)} number: ${error}` ));
                            if (!props.isAdpCustomer )setErrorSerial(result.message);
                        } else {
                            setErrorSerial(`There was an issue validating the ${(props.isAdpCustomer ? VALIDATION_ORDER : VALIDATION_SERIAL)} number...`);
                        }
                    }
                })
                .catch((error) => console.error(`[Validation][validate][ERROR]> There was an issue checking the ${(props.isAdpCustomer ? VALIDATION_ORDER : VALIDATION_SERIAL)} number: ${error}`));
        }
    }

    return (
        <React.Fragment>
            <MDBContainer style={styles} className="my-5">
                <MDBCard>
                    <MDBRow className='g-0'>
                        <MDBCol md='6'>
                            <MDBCardImage src={props.image} alt="validation form" className='rounded-start w-100' />
                        </MDBCol>
                        <MDBCol md='6'>
                            <MDBCardBody className='d-flex flex-column'>
                                <div className='d-flex flex-row mt-2'>
                                    <FontAwesomeIcon style={{ color: props.textIconColour, }}  icon={faFileSignature} size="3x" />
                                    <span style={{ color: props.textIconColour, }}  className="h1 fw-bold mx-2 mb-0">{props.companyName}</span>
                                </div>
                                <h5 className="fw-normal my-4 pb-3" style={{ letterSpacing: '1px' }}>Sign Up Validation</h5>
                                <MDBInput
                                    maxLength={60} wrapperClass='mb-4'
                                    label='Email address' id='formControlEmail'
                                    type='email' size="lg" onChange={updateEmail}
                                    style={errorEmail ? errorStyle : null}
                                    value={emailAddress}
                                >
                                    <div id='emailErrorLabel' className='form-helper'>
                                        {errorEmail}
                                    </div>
                                </MDBInput>
                                <MDBInput maxLength={50} wrapperClass='mb-4'

                                    label={props.isAdpCustomer ? 'Order or PO Number': 'Serial Number of Unit' } id='formControlSerial' type='text' size="lg"
                                    onChange={updateSerial}
                                    value={serialNumber}
                                style={errorSerial ? errorStyle : null}
                                >
                                    <div id='serialErrorLabel' className='form-helper'>
                                        {errorSerial}
                                    </div>
                                </MDBInput>
                                {
                                    !props.isAdpCustomer && categories ?
                                    <Dropdown options={categories} updateChoice={updateChoice} chooseWhat={'Choose a Category'} />
                                    : null
                                }
                                <MDBBtn className={btnClass} color={props.buttonColour} size='lg' onClick={validate}>Validate & Sign Up</MDBBtn>
                                <a className="small text-muted" href="#!" onClick={()=>clickShowValidation(false)}>Back to Login</a>
                                <div className='d-flex flex-row justify-content-start'>
                                    <a href="#!" className="small text-muted me-1">Terms of use.</a>
                                    <a href="#!" className="small text-muted">Privacy policy</a>
                                </div>
                            </MDBCardBody>
                        </MDBCol>
                    </MDBRow>
                </MDBCard>
            </MDBContainer>
        </React.Fragment>
    )
};

export default Validation;
