import React, { Component  } from "react";
import PropTypes from "prop-types";
import './App.css';
import Main from "./components/main/Main";
import { themes } from "./service";
const { string, bool, oneOf, shape } = PropTypes;

const baseUrl = "https://www.cnbcom.ca:8084/";
//const baseUrl = 'http://localhost:8084/';
const header = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'OPTIONS, GET, POST, PUT, PATCH, DELETE',
    'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers: Origin, X-Requested-With, Content-Type, Accept, Key',
    'Access-Control-Allow-Credentials': true,
};
const headerPdf = {
    'Content-Type': 'application/pdf',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'OPTIONS, GET, POST, PUT, PATCH, DELETE',
    'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers: Origin, X-Requested-With, Content-Type, Accept, Key',
    'Access-Control-Allow-Credentials': true,
};

const errorStyle = { color: 'red' };
const provinces = [{
    Value: "Alberta",
    ID: "AB"
},
{
    Value: "British Columbia",
    ID: "BC"
},
{
    Value: "Manitoba",
    ID: "MB"
},
{
    Value: "New Brunswick",
    ID: "NB"
},
{
    Value: "Newfoundland and Labrador",
    ID: "NL"
},
{
    Value: "Northwest Territories",
    ID: "NT"
},
{
    Value: "Nova Scotia",
    ID: "NS"
},
{
    Value: "Nunavut",
    ID: "NU"
},
{
    Value: "Ontario",
    ID: "ON"
},
{
    Value: "Prince Edward Island",
    ID: "PE"
},
{
    Value: "Quebec",
    ID: "QC"
},
{
    Value: "Saskatchewan",
    ID: "SK"
},
{
    Value: "Yukon Territory",
    ID: "YT"
}];
const states = [
    {
        Value: "Alabama",
        ID: "AL"
    },
    {
        Value: "Alaska",
        ID: "AK"
    },
    {
        Value: "American Samoa",
        ID: "AS"
    },
    {
        Value: "Arizona",
        ID: "AZ"
    },
    {
        Value: "Arkansas",
        ID: "AR"
    },
    {
        Value: "California",
        ID: "CA"
    },
    {
        Value: "Colorado",
        ID: "CO"
    },
    {
        Value: "Connecticut",
        ID: "CT"
    },
    {
        Value: "Delaware",
        ID: "DE"
    },
    {
        Value: "District Of Columbia",
        ID: "DC"
    },
    {
        Value: "Federated States Of Micronesia",
        ID: "FM"
    },
    {
        Value: "Florida",
        ID: "FL"
    },
    {
        Value: "Georgia",
        ID: "GA"
    },
    {
        Value: "Guam",
        ID: "GU"
    },
    {
        Value: "Hawaii",
        ID: "HI"
    },
    {
        Value: "Idaho",
        ID: "ID"
    },
    {
        Value: "Illinois",
        ID: "IL"
    },
    {
        Value: "Indiana",
        ID: "IN"
    },
    {
        Value: "Iowa",
        ID: "IA"
    },
    {
        Value: "Kansas",
        ID: "KS"
    },
    {
        Value: "Kentucky",
        ID: "KY"
    },
    {
        Value: "Louisiana",
        ID: "LA"
    },
    {
        Value: "Maine",
        ID: "ME"
    },
    {
        Value: "Marshall Islands",
        ID: "MH"
    },
    {
        Value: "Maryland",
        ID: "MD"
    },
    {
        Value: "Massachusetts",
        ID: "MA"
    },
    {
        Value: "Michigan",
        ID: "MI"
    },
    {
        Value: "Minnesota",
        ID: "MN"
    },
    {
        Value: "Mississippi",
        ID: "MS"
    },
    {
        Value: "Missouri",
        ID: "MO"
    },
    {
        Value: "Montana",
        ID: "MT"
    },
    {
        Value: "Nebraska",
        ID: "NE"
    },
    {
        Value: "Nevada",
        ID: "NV"
    },
    {
        Value: "New Hampshire",
        ID: "NH"
    },
    {
        Value: "New Jersey",
        ID: "NJ"
    },
    {
        Value: "New Mexico",
        ID: "NM"
    },
    {
        Value: "New York",
        ID: "NY"
    },
    {
        Value: "North Carolina",
        ID: "NC"
    },
    {
        Value: "North Dakota",
        ID: "ND"
    },
    {
        Value: "Northern Mariana Islands",
        ID: "MP"
    },
    {
        Value: "Ohio",
        ID: "OH"
    },
    {
        Value: "Oklahoma",
        ID: "OK"
    },
    {
        Value: "Oregon",
        ID: "OR"
    },
    {
        Value: "Palau",
        ID: "PW"
    },
    {
        Value: "Pennsylvania",
        ID: "PA"
    },
    {
        Value: "Puerto Rico",
        ID: "PR"
    },
    {
        Value: "Rhode Island",
        ID: "RI"
    },
    {
        Value: "South Carolina",
        ID: "SC"
    },
    {
        Value: "South Dakota",
        ID: "SD"
    },
    {
        Value: "Tennessee",
        ID: "TN"
    },
    {
        Value: "Texas",
        ID: "TX"
    },
    {
        Value: "Utah",
        ID: "UT"
    },
    {
        Value: "Vermont",
        ID: "VT"
    },
    {
        Value: "Virgin Islands",
        ID: "VI"
    },
    {
        Value: "Virginia",
        ID: "VA"
    },
    {
        Value: "Washington",
        ID: "WA"
    },
    {
        Value: "West Virginia",
        ID: "WV"
    },
    {
        Value: "Wisconsin",
        ID: "WI"
    },
    {
        Value: "Wyoming",
        ID: "WY"
    }];
const rmaTypes = [    
    {
        Value: "Repair",
        ID: "Repair"
    }, {
        Value: "Replace",
        ID: "Replace"
    }, {
        Value: "Refund",
        ID: "Refund"
    },
]
const requestOptions = {
    method: "GET",
    headers: header,
    redirect: "follow",
    changeOrigin: true
};

class App extends Component {    
   
    static propTypes = {
        theme: oneOf(themes),
        bw: bool,
        userName: string,
        companyName: string,
        isAdpCustomer: bool,
        brand: string,
        mainEmail: string,
        mainPhone: string,
        mainSalesEmail: string,
        mainSalesPhone: string,
        mainDevEmail: string,
        brandLogo: string,
        bannerImage: string,
        doShowBanner: bool,
        agreementDoc:string,
        provinces: PropTypes.array.isRequired,
        states: PropTypes.array.isRequired,
        rmaTypes: PropTypes.array.isRequired,
        image: string,
        backToList: string,
        baseUrl: string,
        errorStyle: shape({
            color: string
        }),
        header: shape({
            'Content-Type': string,
            'Access-Control-Allow-Origin': string,
            'Access-Control-Allow-Methods': string,
            'Access-Control-Allow-Headers': string,
            'Access-Control-Allow-Credentials': bool,
        }),
        headerPdf: shape({
            'Content-Type': string,
            'Access-Control-Allow-Origin': string,
            'Access-Control-Allow-Methods': string,
            'Access-Control-Allow-Headers': string,
            'Access-Control-Allow-Credentials': bool,
        }),
        requestOptions: shape({
            method: string,
            headers: shape({
                'Content-Type': string,
                'Access-Control-Allow-Origin': string,
                'Access-Control-Allow-Methods': string,
                'Access-Control-Allow-Headers': string,
                'Access-Control-Allow-Credentials': bool,
            }),
            redirect: string,
            changeOrigin: bool
        }),
        loginLogoMaxHeight: string,
        loginLogoMaxWidth: string,
        textIconColour: string,
        buttonColour: string,
        doOutlineButton: bool,
        failIconColour: string,
        passIconColour: string,
        shieldGuardImage: string,
    };
    
    static defaultProps = {
        theme: "RMA",
        companyName: "CNB Computers RMA",
        image: 'https://irp.cdn-website.com/91b58211/dms3rep/multi/CompFixUpdatedCrop.png',
        backToList: "Back to list",
        baseUrl: baseUrl,
        header: header,
        states: states,
        provinces: provinces,
        errorStyle: errorStyle,
        rmaTypes: rmaTypes,
        bannerImage: 'https://irp.cdn-website.com/91b58211/dms3rep/multi/User+Dashboard_Educational_CNB+Shield+Guard.png',
        doShowBanner: true,
        isAdpCustomer: true,
        brand: "cnb",
        mainEmail: 'Support@CnbComputers.com',
        mainPhone: '905-501-0099',
        mainSalesEmail: 'Sales@CnbComputers.com',
        mainSalesPhone: '905-501-0099',
        mainDevEmail: 'Service@CnbComputers.com',
        brandLogo: '',
        navbarImage: 'https://cnbcomputers.io/media/Images/web/cnbappicon.png',
        loginLogoMaxHeight: '300',
        loginLogoMaxWidth: '600',
        agreementDoc: 'https://irp.cdn-website.com/91b58211/files/uploaded/Ver7.1_USA_CNB_Shield_-_Protection_Plan_Summary_-_Terms_-_Conditions.pdf',
        headerPdf: headerPdf,
        requestOptions: requestOptions,
        textIconColour: 'dark-gray',
        buttonColour: 'dark',
        doOutlineButton: false,
        passIconColour: '#248D1E',
        failIconColour: '#B80F11',
        shieldGuardImage: 'https://irp.cdn-website.com/91b58211/dms3rep/multi/CNB+Shiled+Guard+Logo_SVG.svg',
    };

    render() {
        return (
            <div className="App">
                <header>
                </header>
                <Main props={this.props} />
            </div>
        );
    }
}
export default App;
