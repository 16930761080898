import React, { useState, useEffect } from "react";
import RmaLine from "./RmaLine";
//import styles from "./RmaStatusQuickCheck.module.css";
import {
    MDBBtn,
    //MDBContainer,
    //MDBCard,
    //MDBCardBody,
    //MDBCardImage,
    //MDBRow,
    //MDBCol,
    //MDBIcon,
    MDBInput,
    MDBAccordion,
    MDBAccordionItem
} from 'mdb-react-ui-kit';

const RMA_TOO_SHORT = 'RMA number should be at least 4 digits';
const RMA_TOO_LONG = 'RMA number should be less than 9 digits';
const RMA_SHOULD_CONTAIN = 'RMA number should start with (RMA, SRMA)';
const ENTER_RMA = 'Please enter an RMA number';
const CHECK_BUTTON_TEXT = 'Check RMA Status'
const RmaStatusQuickCheck = ({ props }) => {
    const [rma, setRma] = useState(null);
    const [rmaNumber, setRmaNumber] = useState(null);
    const [rmaDisplayNumber, setRmaDislplayNumber] = useState(null);
    const [errorRma, setErrorRma] = useState(null);
    useEffect(() => { }, [rma], rmaNumber, errorRma);
    let btnClass = `mb-4 px-5 ${(props.doOutlineButton ? `btn-outline-${props.buttonColour}` : '')}`;

    const handeKeyPressRma = (e) => {
        if (e.key === 'Enter') {
            console.log('Enter hit, checking for RMA:', rma);
            getRma();
        }
    }

    const handleRmaCheck = (e) => {
        console.log('RMA Number:', (e.target.value));
        setRmaNumber(e.target.value);
        if (!e.target.value) {
            setErrorRma(ENTER_RMA);
        }
        else if (e.target.value.length < 4) {
            setErrorRma(RMA_TOO_SHORT);
        } else if (e.target.value.length > 9) {
            setErrorRma(RMA_TOO_LONG);
        } else if (!e.target.value.toUpperCase().includes('RMA')) {
            setErrorRma(RMA_SHOULD_CONTAIN);
        } else {
            setErrorRma(null);
        }
    }

    const getRma = () => {
        setRmaDislplayNumber(null);
        const requestOptions = {
            method: "GET",
            headers: props.header,
            redirect: "follow",
            changeOrigin: true
        };

        if (!errorRma && rmaNumber) {
            console.log(`[RmaStatusQuickCheck][getRma][${rmaNumber}]> Checking RMA: ${rmaNumber}`);
            fetch(`${props.baseUrl}RmaStatus/${rmaNumber}`, requestOptions)
                .then((response) => response.json())
                .then((results) => {
                    if (results.status !== 400) {
                        console.log(`[RmaStatusQuickCheck][getRma][${rmaNumber}]> RMA Response: ${JSON.stringify(results)}`);
                        setRma(results);
                        setRmaDislplayNumber(rmaNumber);
                    } else {
                        setErrorRma(results.message);
                    }
                }).catch((error) => {
                    console.error(`[RmaStatusQuickCheck][getRma][${rmaNumber}]> There was an issue getting the RMA(${rmaNumber}): ${error}`);
                })
        }
    }

    return (
        <React.Fragment>
            <h4 className="fw-normal my-4 pb-1" style={{ color: props.textIconColour, letterSpacing: '1px' }}>Quick check the status of an RMA</h4>
            <MDBInput wrapperClass='mb-4'
                label='Enter RMA Number'
                id='rmaNumberTextBox'
                type='text' size="lg"
                style={errorRma ? props.errorStyle : null}
                onChange={handleRmaCheck}
                onKeyPress={handeKeyPressRma}
                >
                    <div id='rmaErrorLabel' className='form-helper'>
                        {errorRma}
                    </div>
            </MDBInput>
            <MDBBtn className={btnClass}  color={props.buttonColour}  size='lg' onClick={getRma}>{CHECK_BUTTON_TEXT}</MDBBtn>
            <MDBAccordion initialActive={1}>
                {rma ? <MDBAccordionItem collapseId={1} headerTitle={rmaDisplayNumber}>
                    <RmaLine props={ props} rma={rma} />
                </MDBAccordionItem> : null}
            </MDBAccordion>
        </React.Fragment>
    );
}


export default RmaStatusQuickCheck;