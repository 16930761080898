import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getIcon } from "../../../service";
const RmaLine = ({ props, rma }) => {

    return (
        <React.Fragment>
            {rma ? rma.map((result, i) => (
                <div key={i}>
                    <div className='d-flex flex-row mt-2'>
                        <FontAwesomeIcon style={{ color: props && props.textIconColour ? props.textIconColour : 'dark-gray', }} icon={getIcon(result.ProdType)} className="me-3" size="4x" />
                        <span style={{ color: props && props.textIconColour ? props.textIconColour : 'dark-gray', }} className="h2 fw-bold mb-0">{`${result.rmaNumber} Line(${result.Status}): ${i + 1}`}</span>
                    </div>
                    <p><b>{'Line ID:'}</b> {result.AutoNo}</p>
                    {result.INVOICENO ? <p><b>{'Invoice Number:'}</b>{result.INVOICENO}</p> : null}
                    {result.SERIALNO ? <p><b>{'Serial Number:'}</b>{result.SERIALNO}</p> : null}
                    {result.ReqType ? <p><b>{'Request:'}</b>{result.ReqType}</p> : null}
                    {result.REASON ? <p><b>{'Reason:'}</b>{result.REASON}</p> : null}
                    <p><b>{'Product:'}</b> {result.DESCRIPTION}</p>
                    <p><b>{'Status:'}</b> {result.Status}</p>
                    {result.StatusNotes ? <p><b>{'Status Notes:'}</b>{result.StatusNotes}</p> : null}
                    {result.Waybill ? <p><b>{'Waybill:'}</b>{result.Waybill}</p> : null}
                    {i + 1 !== rma.length ? <hr />:null}
                </div>
            )
            )
                : null}
        </React.Fragment>
    );
}
export default RmaLine;