import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';

let loadContainer;
export function init({ container, props = {} } = {}) {

    loadContainer = container;
    const root = createRoot(loadContainer);
    root.render(<App {...props} />);

}   

export function clean() {
    if (loadContainer) {
       // ReactDOM.unmountComponentAtNode(loadContainer);
    }
}

if (process.env.NODE_ENV === "development") {
    init({ container: document.getElementById("root") });
}
reportWebVitals();
