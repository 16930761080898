// JavaScript source code
import React from "react";
import styles from "./CategoryDropdown.module.css";


const Dropdown = ({ options, chooseWhat, updateChoice, value }) => {

    function onDropdownSelected(e) {
        updateChoice(e.target.value,
            e.target.name ? e.target.name :
                e.target.children[e.target.value] && e.target.children[e.target.value].innerHTML ?
                    e.target.children[e.target.value].innerHTML :
                    e.target.value);
    }

    let choices = [<option key='0' value='0' name='initialChoice'> { chooseWhat }</option >];
    let i = 1;
    for (var item of options) {
        choices.push(<option key={i} value={item.ID} name={item.Value} >{item.Value}</option>);
        i++;
    }
    return (
        <React.Fragment>
            <select
                className='mb-4 form-control form-control-lg'
                style={styles}
                onChange={onDropdownSelected}
                defaultValue={value}
            >
                {choices}
            </select>
        </React.Fragment>)
};

export default Dropdown;