import { faPhone, faDesktop , faLaptop, faServer,  } from '@fortawesome/free-solid-svg-icons';

export const NOT_ADP = "NOT ADP";
export const getImageSrc = ({ bw, theme, idx }) => {
    const cat = theme || "sports";
    const isbw = bw ? "g" : "c";
    return `https://picsum.photos/seed/picsum/600/600`
};

export const themes = ["RMA", "CnbShieldGuard", "CNB", "Refurbio", "SystemLiquidation"];

export function camelize(str) {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toUpperCase() : word.toLowerCase();
        })
        .replace(/\s+/g, "");
}

export function getIcon(prodType) {
    switch (prodType) {
        case 'Apple phone':
        case 'phone': return faPhone;
        case 'LED':
        case 'LCD':
        case 'Screen':
        case 'Monitor': return faDesktop;
        case 'NbkParts':
        case 'NBK':
        case 'Laptop': return faLaptop;
        case 'SysParts':
        case 'SYS':
        case 'DESKTPOP':
        case 'Dekstop':
        case 'Desktop': return faServer;
        default: return faServer;
    }
}

export function getProdNameForRMA(prodType) {
    switch (prodType) {
        case 'TAB':
        case 'LAP':
        case 'NBKLCD':
        case 'NBK':
        case 'CHR':
            return 'Laptop';

        case 'AIO':
            return 'All-in-One';

        case 'LCD':
            return 'Monitor';

        case 'DMC':
        case 'SER':
        case 'SYS':
        case 'SMC': return 'Desktop';
        case 'ACC':
        case 'ADN':
        case 'BAT':
        case 'DVD':
        case 'ECG':
        case 'HDD':
        case 'KBD':
        case 'MEM':
        case 'MOU':
        case 'PHO':
        case 'PRN':
        case 'PRO':
        case 'PWS':
        case 'STW':
        case 'WATCH':
        default: return 'other';
    }
}

export function getProdName(prodType) {
    switch (prodType) {
        case 'TAB':
            return 'Tablet';
        case 'LAP':
        case 'NBKLCD':
        case 'NBK':
            return 'Laptop';
        case 'CHR':
            return 'Chromebook';
        case 'AIO':
            return 'All-in-One';
        case 'LCD':
            return 'Monitor';
        case 'DMC':
            return 'Dual monitor combination';
        case 'SER':
            return 'Server';
        case 'SYS':
            return 'Desktop';
        case 'SMC':
            return 'Single monitor combination';
        case 'ACC':
            return 'Accessory';
        case 'ADN':
            return 'Add On';
        case 'BAT':
            return 'Battery';
        case 'DVD':
            return 'DVD';
        case 'ECG':
            return 'Electronics';
        case 'HDD':
            return 'Hard Drive';
        case 'KBD':
            return 'Keyboard';
        case 'MEM':
            return 'Memory';
        case 'MOU':
            return 'Mouse';
        case 'PHO':
            return 'Phone';
        case 'PRN':
            return 'Printer';
        case 'PRO':
            return 'Projector';
        case 'STW':
            return 'Software';
        case 'WATCH':
            return 'Watch';
        case 'PWS':
        default: return 'other';
    }
}

export function getWarrantyDetails(product) {
    console.log(`Checking Equal: '${NOT_ADP}' === '${product.WarrantyLength}' :product.WarrantyLength`);
    if (product.WarrantyLength.toString().includes(NOT_ADP)) {
        console.log(`Not an ADP Warranty: ${product.WarrantyLength}`);
        /* Check description for warranty length */
        let warrantyLength = getWarrantyLengthFromDescription(product.Description);
        product.IsCovered = (warrantyLength - product.WarrantyDays) > 0;
        product.RemainingCoverage = (warrantyLength - product.WarrantyDays);
        product.IsADP = false;
    } else {
        console.log(`We have an ADP Warranty: ${product.WarrantyLength}`);
        product.IsCovered = ((product.WarrantyLength*365) - product.WarrantyDays) > 0;
        product.RemainingCoverage = ((product.WarrantyLength * 365) - product.WarrantyDays);
        product.IsADP = true;
    }
}

function getWarrantyLengthFromDescription(description) {
    if (!description || description.includes('/NWR')) {
        return 0;
    }
    else if (description.includes('/1Y')) {
        return 365;
    }
    else if (description.includes('/30D')) {
        return 30;
    }
    else if (description.includes('/90D')) {
        return 90;
    }
    else if (description.includes('/6M')) {
        return 180;
    }
    else if (description.includes('/18M')) {
        return 545;
    }
    else if (description.includes('/3Y')) {
        return 1095;
    }
    else if (description.includes('/2Y')) {
        return 730;
    }
    else if (description.includes('/5Y')) {
        return 1825;
    } else {
        return 365;
    }
}