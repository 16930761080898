import React, { useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import ViewPDF from '../utilities/ViewPDF';
import "./SignAgreement.module.css";
import { faPen, faArrowDown, faArrowCircleRight, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    MDBBtn,
    MDBCardBody,
    //MDBCardImage,
    //MDBIcon,
} from 'mdb-react-ui-kit';

export default function SignAgreement({ props, user, updateSetActive, updateIsAgreementSigned, setSigUrl, setAgreementDoc }) {
    const [isSignature, setIsSignature] = useState(false);
    const [showAgreement, setShowAgreement] = useState(true);
    const [pdf, setPdf] = useState(null);
    const [sigImageUrl, setSigImageUrl] = useState(null);
    const sigCanvas = useRef({});
    const clickShow = () => setShowAgreement(!showAgreement);
    let btnClass = `mb-4 px-5 ${(props.doOutlineButton ? `btn-outline-${props.buttonColour}` : '')}`;
    const clearSignature = () => {
        sigCanvas.current.clear();
        updateIsAgreementSigned(false);
        setIsSignature(false);
        setSigImageUrl(null);
        setSigUrl(null);
    }

    const checkSig = (e) => {
        if (sigCanvas.current.isEmpty()) {
            setIsSignature(false);
        } else {
            setIsSignature(true);
        }
    }
    const blobToBase64 = blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };
    const getPdf = () => {
        console.log('getPdf');
        fetch(props.agreementDoc, { "Content-Type": "application/pdf",})
            .then((response) => response.blob())
            .then(blobToBase64)
            .then((result) => {
                console.log(result.split(',')[1]);
                setPdf(result.split(',')[1] );
            });
    }

    const submit = () => {
        if (!sigCanvas.current.isEmpty()) {
            let imgUrl = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
            setSigImageUrl(imgUrl);
            setSigUrl(imgUrl);
            updateIsAgreementSigned(true);
            setShowAgreement(false);
            setAgreementDoc(pdf);
            if (updateSetActive) updateSetActive();
        }
    }

    if (!pdf) getPdf();

    return (
        <React.Fragment>
            <MDBCardBody className='d-flex flex-column'>
                <MDBBtn className={btnClass} color={props.buttonColour} size='lg' onClick={clickShow}>{showAgreement ? 'Hide' : 'Show'} Agreement</MDBBtn>

                {showAgreement && pdf ? <div >
                    <ViewPDF props={props} pdf={props.agreementDoc} />
                </div> : null}
                <div >
                    <FontAwesomeIcon style={{ color: props.textIconColour, }} size="2x" icon={faPen} flip="horizontal" />
                    <FontAwesomeIcon style={{ color: props.textIconColour, }} size="2x" icon={faArrowDown} />
                    <span style={{ color: props.textIconColour, }} className="h2 fw-bold mb-0 mx-5">Sign Below</span>
                    <FontAwesomeIcon style={{ color: props.textIconColour, }} size="2x" icon={faArrowDown} />
                    <FontAwesomeIcon style={{ color: props.textIconColour, }} size="2x" icon={faPen} />
                </div>
                <div style={{ width: 600, height: 200, border: '3px dotted  black', alignSelf: 'center', marginBottom: '10px' }}>

                    <SignatureCanvas
                        onEnd={checkSig}
                        pnBegin={checkSig}
                        ref={sigCanvas}
                        penColor={
                            props.brand === 'refurbio' ? 'green' : props.brand === 'sysLiq' ? 'blue' : 'black'}
                        canvasProps={{ width: 600, height: 200, className: 'signatureCanvas' }} />

                </div>
                <MDBBtn className={btnClass} color={props.buttonColour} size='lg' disabled={isSignature && sigImageUrl} onClick={submit}>Agree & Submit</MDBBtn>

                <MDBBtn className={btnClass} color={props.buttonColour} size='lg' disabled={!isSignature} onClick={clearSignature}>Clear</MDBBtn>
            </MDBCardBody>
        </React.Fragment>
    )
}
