import React, { useState, useEffect } from "react";
//import { getImageSrc, camelize } from "../../service";
import Dropdown from "../categories/CategoryDropdown";
//import styles from "./SignUp.module.css";
import {
    //MDBBtn,
    //MDBContainer,
    //MDBCard,
    MDBCardBody,
    //MDBCardImage,
    MDBRow,
    MDBCol,
    //MDBIcon,
    MDBInput
} from 'mdb-react-ui-kit';
/**
 *
 * @param {any} param0
 */
const PROV_STATE = 'Province/State *';
const PROV = 'Province *';
const STATE = 'State *';
const COUNTRY_CHOOSE = 'Country *';
const CANADA_NAME = 'Canada';
const USA_NAME = 'USA';
const CANADA_CODE = 'CA';
const USA_CODE = 'US';
const CAN_IDENTIFIERS = [CANADA_NAME, CANADA_CODE];
const US_IDENTIFIERS = [USA_NAME, USA_CODE];
const PROV_STATE_NAMES = [PROV_STATE, PROV, STATE];
const POSTAL_ZIP = 'Postal/Zip Code';
const POSTAL = 'Postal Code';
const regexPostalCode = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
const regexZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
const ZIP = 'Zip Code';
const CHOOSE_PROVINCE = 'Please Choose a ';
const CITY_TWO_ERROR = 'City should be 2 or more characters';
const NAME_TOO_LONG_ERROR = 'Name should be 50 characters or less';
const COMPANY_NAME_TOO_LONG_ERROR = 'Company Name should be 30 characters or less';
const NAME_TOO_SHORT_ERROR = 'Name should be 2 or more characters';
const COMPANY_NAME_TOO_SHORT_ERROR = 'Company Name should be 2 or more characters';
const ADDRESS_FIVE_ERROR = 'Address should be 5 or more characters';
const PHONE = 'Phone';
const FAX = 'Fax';
const PHONE_LENGTH_ERROR = 'Number should be 10 digits in length (XXX)XXX-XXXX';
const PHONE_CHAR_ERROR = 'Number should not contain letters or special characters';
const regexPhone = /^[0-9]{1,10}$/;

/* Postal Code */
const CHOOSE_COUNTRY_POSTAL_ERROR = 'Please Choose a Country to validate Postal Code';
const POSTAL_CODE_FORMAT_ERROR = 'Postal Code is not the correct format';

const countries = [{ ID: 'CA', Value: CANADA_NAME }, { ID: 'US', Value: USA_NAME }];
const languageOptions = [{ ID: "ENG", Value: 'English' }, { ID: "FRE", Value: 'French' },];
const Address = ({ setAddress, props, currentAddress, user }) => {
    const [preferredLanguage, setPreferredLanguage] = useState({ ID: 'ENG', Value: 'English' });
    const [address1, setAddress1] = useState(currentAddress && currentAddress.Address1 ? currentAddress.Address1 : null);
    const [extension, setExtension] = useState(currentAddress && currentAddress.EXT ? currentAddress.EXT : null);
    const [city, setCity] = useState(currentAddress && currentAddress.Address2 ? currentAddress.Address2 : null);
    const [provinceState, setProvinceState] = useState(currentAddress && currentAddress.State ? currentAddress.State : null);
    const [provinceStateError, setProvinceStateError] = useState(null);
    const [countryError, setCountryError] = useState(null);
    const [addressError, setAddressError] = useState(null);
    const [cityError, setCityError] = useState(null);
    const [postalCodeError, setPostalCodeError] = useState(null);
    const [country, setCountry] = useState(currentAddress && currentAddress.Country ? currentAddress.Country : null);
    const [postalCode, setPostalCode] = useState(currentAddress && currentAddress.Zip ? currentAddress.Zip : null);
    const [provincesStates, setProvincesStates] = useState([...props.provinces, ...props.states]);
    const [postalName, setPostalName] = useState(POSTAL_ZIP);
    const [provName, setProvName] = useState(PROV_STATE);
    const [phone, setPhone] = useState(currentAddress && currentAddress.TEL ? currentAddress.TEL : null);
    const [phoneError, setPhoneError] = useState(null);
    const [fax, setFax] = useState(currentAddress && currentAddress.FAX ? currentAddress.FAX : null);
    const [faxError, setFaxError] = useState(null);
    const [fullName, setFullName] = useState(currentAddress && currentAddress.ATTN ? currentAddress.ATTN : null);
    const [companyName, setCompanyName] = useState(currentAddress && currentAddress.Company ? currentAddress.Company : null);
    const [fullNameError, setFullNameError] = useState(null);
    const [companyNameError, setCompanyNameError] = useState(null);

    useEffect(() => { updateAddress(); }, [provinceStateError, countryError, cityError,
        postalCodeError, addressError, phoneError, faxError, fullNameError, companyNameError, postalCode,
        country, extension, fax, provinceState, fullName, phone, city, address1, companyName, preferredLanguage]);

    const updateFullName = (e) => {
        console.log(`updateFullName: ${e.target.value}`);
        setFullName(e.target.value);
        if (e.target.value.length > 50) {
            setFullNameError(NAME_TOO_LONG_ERROR);
            return;
        } else if (e.target.value.length < 2) {
            setFullNameError(NAME_TOO_SHORT_ERROR);
            return;
        } else {
            setFullNameError(null);
        }
        updateAddress();
    }

    const updateCompanyName = (e) => {
        console.log(`updateCompanyName: ${e.target.value}`);
        setCompanyName(e.target.value);
        if (e.target.value.length > 30) {
            setCompanyNameError(COMPANY_NAME_TOO_LONG_ERROR);
            return;
        } else if (e.target.value.length < 2) {
            setCompanyNameError(COMPANY_NAME_TOO_SHORT_ERROR);
            return;
        } else {
            setCompanyNameError(null);
        }
        updateAddress();
    }

    const updateCountry = (id, name) => {
        console.log(`updateCountry: ${id} ${name}`);
        setCountry({ ID: id, Value: name });
        /* Switch Province State */
        if (name === CANADA_NAME || name === 'CA') {
            setProvincesStates([...props.provinces]);
            setProvName(PROV);
            setPostalName(POSTAL);
            setCountryError(null);
        }
        else if (name === USA_NAME || name === 'US') {
            setProvincesStates([...props.states]);
            setProvName(STATE);
            setPostalName(ZIP);
            setCountryError(null);
        }
        else {
            setProvincesStates([...props.provinces, ...props.states]);
            setProvName(PROV_STATE);
            setPostalName(POSTAL_ZIP);
            setCountryError('Please Choose a Country');
        }
        isPostalCodeInvalid(postalCode, { ID: id, Value: name });
        updateAddress();
    };

    const updatePreferredLanguage = (id, name) => {
        console.log(`updatePreferredLanguage: ${id} ${name}`);
        setPreferredLanguage({ ID: id, Value: name });
    };

    const updateProviceState = (id, name) => {
        console.log(`updateProviceState: ${id} ${name}`);
        setProvinceState({ ID: id, Value: name });
        if (PROV_STATE_NAMES.includes(name)) {
            /* Set Error Province State */
            setProvinceStateError(`${CHOOSE_PROVINCE} ${provName}`)
            return;
        } else {
            setProvinceStateError(null);
        }
        updateAddress();
    };

    const updateAddress1 = (e) => {
        console.log(`updateAddress: ${e.target.value}`);
        setAddress1(e.target.value);
        if (e.target.value.length < 5) {
            setAddressError(ADDRESS_FIVE_ERROR);
            return;
        } else {
            setAddressError(null);
        }
        updateAddress();
    };
    const updateExtension = (e) => {
        console.log(`updateExtension: ${e.target.value}`);
        setExtension(e.target.value);
        updateAddress();
    };
    const updateCity = (e) => {
        setCity(e.target.value);
        console.log(`updateCity: ${e.target.value}`);
        if (e.target.value.length < 2) {
            setCityError(CITY_TWO_ERROR);
            return;
        } else {
            setCityError(null);
        }
        updateAddress();
    };


    const updatePhone = (e) => {
        console.log(`updatePhone: ${e.target.value}`);
        setPhone(e.target.value);
        if (!regexPhone.test(e.target.value)) {
            setPhoneError(`${PHONE} ${PHONE_CHAR_ERROR}`);
            return;
        } else if (e.target.value.length !== 10) {
            setPhoneError(`${PHONE} ${PHONE_LENGTH_ERROR}`);
            return;
        } else {
            setPhoneError(null);
        }
        updateAddress();
    };
    const updateFax = (e) => {
        setFax(e.target.value);
        console.log(`updateFax: ${e.target.value}`);
        if (e.target.value.length === 0) {
            setFaxError(null);
            return;
        } else if (!regexPhone.test(e.target.value)) {
            setFaxError(`${FAX} ${PHONE_CHAR_ERROR}`);
            return;
        } else if (e.target.value.length !== 10) {
            setFaxError(`${FAX} ${PHONE_LENGTH_ERROR}`);
            return;
        } else {
            setFaxError(null);
        }
        updateAddress();
    };

    function isPostalCodeInvalid(code, coun) {
        console.log(`isPostalCodeInvalid: ${code} Country: ${coun ? coun.Value : ''} Pass Postal: ${regexPostalCode.test(code)} Pass Zip: ${regexZip.test(code)}`);
        if (!coun || !coun.Value) {
            console.log(`Please Choose a Country to validate Postal Code: ${code} Pass Postal: ${regexPostalCode.test(code)} Pass Zip: ${regexZip.test(code)}`);
            setPostalCodeError(CHOOSE_COUNTRY_POSTAL_ERROR);
            return true;
        }
        else if (CAN_IDENTIFIERS.includes(coun.Value) && !regexPostalCode.test(code)) {
            console.log(`Postal Code is not the correct format: ${code} Country: ${coun.Value} Pass Postal: ${regexPostalCode.test(code)} Pass Zip: ${regexZip.test(code)}`);
            setPostalCodeError(POSTAL_CODE_FORMAT_ERROR);
            return true;
        }
        else if (US_IDENTIFIERS.includes(coun.Value) && !regexZip.test(code)) {
            console.log(`Zip Code is not the correct format: ${code} Country: ${coun.Value} Pass Postal: ${regexPostalCode.test(code)} Pass Zip: ${regexZip.test(code)}`);
            setPostalCodeError('Zip Code is not the correct format');
            return true;
        }
        else if (US_IDENTIFIERS.includes(coun.Value) && regexPostalCode.test(code)) {
            console.log(`Zip Code is not the correct format for Country: ${code} Country: ${coun.Value} Pass Postal: ${regexPostalCode.test(code)} Pass Zip: ${regexZip.test(code)}`);
            setPostalCodeError('Zip Code is not the correct format for Country');
            return true;
        }
        else if (CAN_IDENTIFIERS.includes(coun.Value) && regexZip.test(code)) {
            console.log(`Postal Code is not the correct format for Country: ${code} Country: ${coun.Value} Pass Postal: ${regexPostalCode.test(code)} Pass Zip: ${regexZip.test(code)}`);
            setPostalCodeError('Postal Code is not the correct format for Country');
            return true;
        }
        else if (coun.Value === COUNTRY_CHOOSE || coun.Value === 1) {
            console.log(`Please Choose a Country to validate Postal Code: ${code} Country: ${coun.Value} Pass Postal: ${regexPostalCode.test(code)} Pass Zip: ${regexZip.test(code)}`);
            setPostalCodeError('Please Choose a Country to validate Postal Code');
            return true;
        }
        console.log(`All Passed, removing error: ${code} Country: ${coun.Value} Pass Postal: ${regexPostalCode.test(code)} Pass Zip: ${regexZip.test(code)}`);

        setPostalCodeError(null);
        updateAddress();
        return false;
    }

    const updatePostalCode = (e) => {
        console.log(`updatePostalCode: ${e.target.value} Pass Postal: ${regexPostalCode.test(e.target.value)} Pass Zip: ${regexZip.test(e.target.value)}`);
        setPostalCode(e.target.value);
        isPostalCodeInvalid(e.target.value, country);
        updateAddress();
    };

    const updateAddress = () => {
        console.log('Address Valid', provinceStateError, countryError, cityError,
            postalCodeError, addressError, phoneError, faxError, fullNameError, companyNameError,
            postalCode, fullName, city, provinceState, companyName, country, phone);

        if (!addressError && !cityError && !provinceStateError && !postalCodeError && !countryError && !phoneError && !faxError
            && postalCode && fullName && city && provinceState && companyName && country && phone) {
            setAddress({
                FullName: fullName,
                CompanyName: companyName,
                Address: address1,
                City: city,
                Province: provinceState,
                Country: country,
                PostalCode: postalCode,
                Ext: extension,
                Phone: phone,
                Fax: fax,
                PreferredLanguage: preferredLanguage,
            });
        }
        else {
            setAddress(null);
        }
    }

    return (
        <React.Fragment>
            <form id="frmAddress">
                <MDBRow className='g-0'>
                    <MDBCol md='6' className=''>
                        <MDBInput wrapperClass='mb-4' maxLength={30}
                            onChange={updateCompanyName} label='Company Name *'
                            id='formControlCompany' type='text' size="lg"
                            defaultValue={currentAddress && currentAddress.Company ? currentAddress.Company : null}
                            style={companyNameError ? props.errorStyle : null} >
                            <div id='formCompanyNameErrorLabel' className='form-helper'>
                                {companyNameError}
                            </div>
                        </MDBInput>
                        <MDBInput wrapperClass='mb-4' maxLength={50}
                            onChange={updateFullName} label='Full Name *' id='formControlName'
                            defaultValue={currentAddress && currentAddress.ATTN ? currentAddress.ATTN : null}
                            type='text' size="lg" style={fullNameError ? props.errorStyle : null}>
                            <div id='formNameErrorLabel' className='form-helper'>
                                {fullNameError}
                            </div>
                        </MDBInput>
                        <MDBInput wrapperClass='mb-4' maxLength={50} label='Street Address *'
                            onChange={updateAddress1} id='formAddress' type='text' size="lg"
                            defaultValue={currentAddress && currentAddress.Address1 ? currentAddress.Address1 : null}
                            style={addressError ? props.errorStyle : null}
                        >
                            <div id='formAddressErrorLabel' className='form-helper'>
                                {addressError}
                            </div>
                        </MDBInput>
                        <MDBInput wrapperClass='mb-4' label='City *' onChange={updateCity}
                            id='formCity' type='text' size="lg"
                            defaultValue={currentAddress && currentAddress.Address2 ? currentAddress.Address2 : null}
                            style={cityError ? props.errorStyle : null}
                        >
                            <div id='formCityErrorLabel' className='form-helper'>
                                {cityError}
                            </div>
                        </MDBInput>
                        <MDBInput wrapperClass='mb-4' label={`${postalName} *`} onChange={updatePostalCode}
                            id='formPostalCode' type='text' size="lg"
                            defaultValue={currentAddress && currentAddress.Zip ? currentAddress.Zip : null}
                            style={postalCodeError ? props.errorStyle : null}
                        >
                            <div id='formPostalErrorLabel' className='form-helper'>
                                {postalCodeError}
                            </div>
                        </MDBInput>
                    </MDBCol>
                    <MDBCol md='6'>
                        <Dropdown options={countries} updateChoice={updateCountry} chooseWhat={COUNTRY_CHOOSE}
                            value={currentAddress && currentAddress.Country ? currentAddress.Country : null}
                        />
                        <Dropdown options={provincesStates} updateChoice={updateProviceState} chooseWhat={provName}
                            value={currentAddress && currentAddress.State ? currentAddress.State : null}
                        />
                        <MDBInput wrapperClass='mb-4' maxLength={10} label='Phone Number *'
                            onChange={updatePhone} id='formPhone' type='tel' size="lg"
                            defaultValue={currentAddress && currentAddress.TEL ? currentAddress.TEL : null}
                            style={phoneError ? props.errorStyle : null}
                        >
                            <div id='formPhoneErrorLabel' className='form-helper'>
                                {phoneError}
                            </div>
                        </MDBInput>
                        <MDBInput wrapperClass='mb-4' maxLength={4} label='Extension'
                            onChange={updateExtension} id='formExtension'
                            defaultValue={currentAddress && currentAddress.EXT ? currentAddress.EXT : null}
                            type='tel' size="lg" />
                        <MDBInput wrapperClass='mb-4' label='Fax Number' onChange={updateFax} id='formFax'
                            type='tel' size="lg"
                            defaultValue={currentAddress && currentAddress.FAX ? currentAddress.FAX : null}
                            style={faxError ? props.errorStyle : null}
                        >
                            <div id='formFaxErrorLabel' className='form-helper'>
                                {faxError}
                            </div>
                        </MDBInput>
                        <Dropdown options={languageOptions} updateChoice={updatePreferredLanguage} chooseWhat={'Preferred Language'} value={user && user.Language ? user.Language : 'English'} />
                    </MDBCol>
                    <MDBCol md='6'>
                    </MDBCol>
                </MDBRow>
            </form>
        </React.Fragment>
    );
}
export default Address;